import { memo, useState, useEffect, useCallback } from 'react'
import { useIntl } from 'react-intl'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import { Props as ParentProps, OnClose } from './More'
import DeleteDialogComponent from './DeleteDialog'

interface Props {
    itemLength: ParentProps['itemLength'];
    title: ParentProps['title'];
    survey_workspace_group_no: ParentProps['survey_workspace_group_no'];
    refetch: ParentProps['refetch'];
    onClose: OnClose;
    onChange: ParentProps['onChange'];
}

function DeleteGroupComponent(props: Props) {
    const { itemLength, title, survey_workspace_group_no, refetch, onClose, onChange } = props

    const [ open, setOpen ] = useState(false)

    const { formatMessage: f } = useIntl()

    const handleOpen = () => {
        setOpen(true)
        onClose()
    }

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])
    
    useEffect(() => {
        return () => {
            setOpen(false)
        }
    }, [])

    if (itemLength < 2) return null
    if (!survey_workspace_group_no) return null

    return (
        <>
        <MenuItem dense onClick={handleOpen}>
            <ListItemIcon>
                <RemoveCircleOutlineIcon fontSize="small" color='secondary'/>
            </ListItemIcon>
            <Typography variant="inherit" color='secondary'>
                {f({id: 'component.Survey.Workspace.Left.Group.DeleteGroup.title'})}
            </Typography>
        </MenuItem>
        <DeleteDialogComponent 
            title={title} 
            survey_workspace_group_no={survey_workspace_group_no} 
            open={open} 
            refetch={refetch}
            onChange={onChange}
            onClose={handleClose}
        />
        </>
    )
}

export default memo(DeleteGroupComponent)