import { useState, useCallback, useEffect, memo } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { useMutation } from '@apollo/react-hooks'
import { isEqual } from 'lodash-es'
import moment from 'moment'
import oc from 'open-color'
import { getBackgroundImageOpacity, getBackgroundPath } from 'utils/survey'
import { getPolicyOpen } from 'policy'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as POPOVERSHOW, HIDE as POPOVERHIDE } from 'reducer/popover'
import { UPDATE_SURVEY_MBOX_OPEN_RETURN_SURVEY_MBOX } from 'gql/survey_mbox'
import { Card, Typography, IconButton } from '@material-ui/core'
import { MoreHoriz as MoreHorizIcon, LockOutlined as LockOutlinedIcon, LockOpen as LockOpenIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from 'styled/Custom/Tooltip'
import { SurveyWorkspaceBodyListItemMoreComponent } from 'component'
import ListItemResponseCountComponent from './ListItemResponseCount'

export const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative'
    },
    card: props => {
        const { background_color, background_image, background_opacity, background_filter, height } = props

        // 필터가 들어갔다면 이미지가 다르다
        const backgroundImage = getBackgroundPath({ background_image, background_filter })

        return {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            height: height || 180,
            backgroundColor: `rgba(${background_color})`,
            backgroundImage: getBackgroundImageOpacity({ background_opacity, background_image: backgroundImage }),
            backgroundSize: 'cover',
            position: 'relative'
        }
    },
    title: props => {
        const { question_color, fontFamily } = props

        return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1,
            color: `rgba(${question_color})`,
            fontFamily,
            wordBreak: 'break-all',
            padding: 10,
            cursor: 'pointer'
        }
    },
    details: {
        display: 'flex',
        width: '100%',
        height: 35,
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 10,
        background: oc.gray[0]
    },
    date: {
        color: oc.gray[6]
    }
}))

const Component = (props) => {
    const { 
        survey_no, 
        title, 
        wdate, 
        open, 
        end_date, 
        end_date_used, 
        survey_mbox_design,
        survey_analysis_user,
        onLocation, 
        onChangeOpenPreview, 
        onChangeOpenUodateSurveyTitle, 
        onChangeOpenRemoveSurvey, 
        onChangeOpenCopySurvey,
        onChangeOpenMoveSurvey
    } = props
    const { question: question_color, background_color, background_image, background_opacity, background_filter, font_family } = survey_mbox_design

    const { today_end_count, is_response } = survey_analysis_user

    const { formatMessage: f } = useIntl()
    
    const classes = useStyles({ question_color, background_color, background_image, background_filter, background_opacity, fontFamily: font_family })

    const dispatch = useDispatch()

    // 공개 설정
    const [ _open, _setOpen ] = useState(getPolicyOpen({ open, end_date, end_date_used }))


    const [ updateSurveyMboxOpenReturnSurveyMbox ] = useMutation(UPDATE_SURVEY_MBOX_OPEN_RETURN_SURVEY_MBOX, {
        onCompleted: async (data) => {
            const { open, end_date, end_date_used } = data.updateSurveyMboxOpenReturnSurveyMbox
            _setOpen(getPolicyOpen({ open, end_date, end_date_used }))
        },
        onError: async() => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })
    
    const changeOpen = useCallback(() => {
        updateSurveyMboxOpenReturnSurveyMbox({
            variables: {
                survey_no,
                open: !_open
            }
        })
    }, [_open, survey_no, updateSurveyMboxOpenReturnSurveyMbox])

    const type = is_response ? 'analysis' : 'create'

    const changeMore = useCallback((e) => {
        dispatch({ 
            type: POPOVERSHOW, 
            component: (
                <SurveyWorkspaceBodyListItemMoreComponent 
                    survey_no={survey_no}
                    title={title}
                    onLocation={onLocation} 
                    onChangeOpenPreview={onChangeOpenPreview}
                    onChangeOpenUodateSurveyTitle={onChangeOpenUodateSurveyTitle}
                    onChangeOpenRemoveSurvey={onChangeOpenRemoveSurvey}
                    onChangeOpenCopySurvey={onChangeOpenCopySurvey}
                    onChangeOpenMoveSurvey={onChangeOpenMoveSurvey}
                />
            ),
            anchorEl: e.currentTarget,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
            },
            transformOrigin: {
                vertical: 'top',
                horizontal: 'center'
            },
            isClose: false
        })

    }, [dispatch, survey_no, title, onLocation, onChangeOpenPreview, onChangeOpenUodateSurveyTitle, onChangeOpenRemoveSurvey, onChangeOpenCopySurvey, onChangeOpenMoveSurvey])

    useEffect(() => {

        return () => {
            dispatch({ type: POPOVERHIDE })
        }
    }, [dispatch])

    return (
        <div className={classes.root}>
            <ListItemResponseCountComponent survey_no={survey_no} today_end_count={today_end_count} is_response={is_response} onLocation={onLocation} />
            <Card className={classes.card}>
                <Typography variant="body1" component="div" className={classes.title} onClick={() => onLocation({type, survey_no})}>{title}</Typography>
                <div className={classes.details}>
                    <div>
                        <Typography variant="body2" className={classes.date}>{moment(Number(wdate)).format('YYYY.MM.DD')}</Typography>
                    </div>
                    <div>
                        <Tooltip title={_open ? f({id: 'component.Survey.Workspace.Body.ListItem.open.0'}) : f({id: 'component.Survey.Workspace.Body.ListItem.open.1'})} isMobileClick={true}>
                            <IconButton size="small" onClick={changeOpen}>
                                {
                                    _open ? <LockOpenIcon fontSize="small"/> : <LockOutlinedIcon fontSize="small"/>
                                }
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div>
                        <IconButton size="small" onClick={changeMore}>
                            <MoreHorizIcon/>
                        </IconButton>
                    </div>
                </div>
            </Card>
        </div>
        
    )
}

export default memo(Component, (prev, next) => {
    return  isEqual(prev, next)
})