/**
 * 그룹 in survey_workspace 리스트 가져오기
 */
import { memo } from 'react'
import { useIntl } from 'react-intl'
import { SurveyWorkspacesGroupBys } from 'gql/survey_workspace_group'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/core/styles'
import { getTitle } from '../Left/Lists'
import { Props as ParentProps, Type } from './WorkspaceGroupByData';

interface Props {
    rows: SurveyWorkspacesGroupBys[];
    type: ParentProps['type'];
    targetSurveyWorkspaceGroupNo: ParentProps['targetSurveyWorkspaceGroupNo'];
    targetSurveyWorkspaceNo: ParentProps['targetSurveyWorkspaceNo'];
    survey_workspace_no: ParentProps['survey_workspace_no'];
    onChangeWorkspaceGroup: ParentProps['onChangeWorkspaceGroup'];
    onChangeWorkspace: ParentProps['onChangeWorkspace'];
}

const useStyles = makeStyles(theme => ({
    form: {
        marginTop: theme.spacing(3)
    }
}))

function WorkspaceGroupByItems(props: Props) {
    const { type, rows, targetSurveyWorkspaceGroupNo, targetSurveyWorkspaceNo, survey_workspace_no, onChangeWorkspaceGroup, onChangeWorkspace } = props

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const { survey_workspaces: workspaces } = rows.find(c => c.survey_workspace_group_no === targetSurveyWorkspaceGroupNo) || { survey_workspaces: [] }
    
    // 이동일 경우 해당 작업함은 안보여준다
    const newWorkspaces = type === Type.Create ? workspaces : workspaces.filter(c => Number(c.survey_workspace_no) !== Number(survey_workspace_no))
    const isSelectedWorkspace = newWorkspaces.find(c => targetSurveyWorkspaceNo === c.survey_workspace_no) ? targetSurveyWorkspaceNo : ''

    const intl = type === Type.Create ? 'CopySurvey' : 'MoveSurvey'
    
    return (
        <>
        <FormControl fullWidth className={classes.form}>
            <InputLabel htmlFor="group">{f({id: `component.Survey.Workspace.Body.${intl}.workspaceGroup.label`})}</InputLabel>
            <Select value={targetSurveyWorkspaceGroupNo} onChange={(e) => {
                onChangeWorkspaceGroup(Number(e.target.value))
                onChangeWorkspace('')
            }} autoFocus>
                {
                    rows.map(c => (
                        <MenuItem 
                            key={c.survey_workspace_group_no} 
                            value={c.survey_workspace_group_no}
                        >
                            {<Typography variant="body2">{c.title || f({id: 'component.Survey.Workspace.Body.MoveWorkspaceButtonSelect.default'})}</Typography>}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
        <FormControl fullWidth className={classes.form}>
            <InputLabel htmlFor="folder">{f({id: `component.Survey.Workspace.Body.${intl}.workspace.label`})}</InputLabel>
            <Select value={isSelectedWorkspace} onChange={(e) => onChangeWorkspace(Number(e.target.value))}>
                {
                    newWorkspaces.map(c => (
                        <MenuItem 
                            key={c.survey_workspace_no} 
                            value={c.survey_workspace_no}
                        >
                            {<Typography variant="body2">{getTitle(f, c)}</Typography>}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
        </>
    )
}

export default memo(WorkspaceGroupByItems)