import { memo } from 'react'
import { useIntl } from 'react-intl'
import { Step } from 'gql/survey_analysis_summary'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { OnChangeStep } from './Condition'

interface Props {
    step: Step;
    onChange: OnChangeStep;
}

function SearchComponent(props: Props) {
    const { step, onChange } = props

    const { formatMessage: f } = useIntl()

    return (
        <Select value={step} onChange={onChange} variant="outlined">
            <MenuItem value={Step.End}>{f({id: 'component.Survey.Workspace.Left.Utility.Download.ConditionSelect.item.1'})}</MenuItem>
            <MenuItem value={Step.Ing}>{f({id: 'component.Survey.Workspace.Left.Utility.Download.ConditionSelect.item.2'})}</MenuItem>
        </Select>
    )
}

export default memo(SearchComponent)