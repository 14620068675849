/**
 * 응답 수 가져오기 버튼
 */
import { memo } from 'react'
import { useIntl } from 'react-intl'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

interface Props {
    loading: boolean;
    onClick: () => void;
}


const useStyles = makeStyles(theme => ({
    button: {
        position: 'absolute', 
        top: 0, 
        right: 0, 
        zIndex: 1, 
        height: 22, 
        fontSize: 12,
        borderRadius: '0 3px 0 0'
    },
    loading: {
        position: 'absolute', 
        top: 1, 
        right: 1, 
        zIndex: 1, 
        width: 3,
        height: 3
    }
}))

function ListItemResponseCountButtonComponent(props: Props) {
    const { loading, onClick } = props

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    if (loading) return <CircularProgress className={classes.loading} style={{ width: 16, height: 16}} />

    return (
        <Button className={classes.button} color="default" size="small" variant="contained" disableElevation onClick={onClick}>
            {f({id: 'component.Survey.Workspace.Body.ListItemResponseCountButton.button'})}
        </Button>
    )
} 

export default memo(ListItemResponseCountButtonComponent)