import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { RootState } from 'reducer'
import { CHANGE } from 'reducer/survey/workspace/order'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

function OrderComponent() {
    const { value } = useSelector((state: RootState) => state.surveyWorkspaceOrder)

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch({ type: CHANGE, value: e.target.value })
    }

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel>{f({id: 'component.Survey.Workspace.Body.Order.label'})}</InputLabel>
            <Select value={value} label={f({id: 'component.Survey.Workspace.Body.Order.label'})} style={{height: 45, background: 'white'}} onChange={handleChange}>
                <MenuItem value={0}>{f({id: 'component.Survey.Workspace.Body.Order.item.0'})}</MenuItem>
                <MenuItem value={1}>{f({id: 'component.Survey.Workspace.Body.Order.item.1'})}</MenuItem>
            </Select>
        </FormControl>
    )
}

export default OrderComponent