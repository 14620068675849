import { memo, useState, useCallback, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import { UPDATE_SURVEY_WORKSPACE_GROUP_NO } from 'gql/survey_workspace'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { 
    InputAdornment, 
    Button,
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    Typography
} from '@material-ui/core'
import { SurveyWorkspaceProps } from 'gql/survey_workspace'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'
import MoveWorkspaceButtonSelectComponent from './MoveWorkspaceButtonSelect'
import { getDefaultWorkspaceGroupName } from '../Left/Group/Items'

interface Props {
    survey_workspace_no: SurveyWorkspaceProps['survey_workspace_no'];
    onChangeSelected: (a: number | null) => void;
    refetchSurveyWorkspaces: () => void;
    breakpoints: BreakPointsProps
}

export type Selected = {
    survey_workspace_group_no: number;
    title: string;
}
export type OnChange = (e: Selected) => void;

const initialState = {
    selected: {
        survey_workspace_group_no: 0,
        title: ''
    }
}

function MoveWorkspaceComponent(props: Props) {
    const { survey_workspace_no, onChangeSelected, refetchSurveyWorkspaces, breakpoints } = props
    const { xs } = breakpoints

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const [ open, setOpen ] = useState(false)
    const [ selected, setSelected ] = useState<Selected>(initialState.selected)

    const [ update ] = useMutation(UPDATE_SURVEY_WORKSPACE_GROUP_NO, {
        onCompleted: (data) => {
            setTimeout(() => {
                refetchSurveyWorkspaces()
            }, 100)
            setTimeout(() => {
                setOpen(false)
                
                dispatch({ type: BACKDROPHIDE })

                const moveTitle = getDefaultWorkspaceGroupName(f, selected.title)
                
                dispatch({
                    type: ALERTSNACKBARSHOW, 
                    message: f({id: 'component.Survey.Workspace.Body.MoveWorkspaceButton.alert'}, { moveTitle }), 
                    variant: 'info'
                })

                onChangeSelected(data.updateSurveyWorkspaceGroupNo)
            }, 150)
        },
        onError: () => {

            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleChange: OnChange = useCallback(props => {
        const { survey_workspace_group_no, title } = props
 
        setSelected({ survey_workspace_group_no, title })
    }, [])

    const handleSave = () => {
        dispatch({ type: BACKDROPSHOW })
        update({
            variables: {
                survey_workspace_no,
                target_survey_workspace_group_no: selected.survey_workspace_group_no
            }
        })
    }

    useEffect(() => {
        setSelected(initialState.selected)
    }, [open])
    
    if (xs) return null

    return (
        <>
        <InputAdornment position="end" onClick={handleOpen}>
            <Button color="default" size="small" style={{color: oc.gray[7]}}>
                {f({id: 'component.Survey.Workspace.Body.MoveWorkspaceButton.button.move'})}
            </Button>
        </InputAdornment>
        <Dialog open={open} onClose={handleClose} fullWidth fullScreen={xs}>
            <DialogTitle>
                {f({id: 'component.Survey.Workspace.Body.MoveWorkspaceButton.dialog.title'})}
            </DialogTitle>
            <DialogContent>
                <MoveWorkspaceButtonSelectComponent selected={selected} onChange={handleChange}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave} color="primary" disabled={selected.survey_workspace_group_no === 0}>
                    <Typography variant="button">
                        {f({id: 'component.Survey.Workspace.Body.MoveWorkspaceButton.dialog.button.move'})}
                    </Typography>
                </Button>
                <Button onClick={handleClose} color="default">
                    <Typography variant="button">
                        {f({id: 'component.Survey.Workspace.Body.MoveWorkspaceButton.dialog.button.close'})}
                    </Typography>
                </Button>
            </DialogActions>
        </Dialog>
        </>
    )
}

export default withBreakPoints(memo(MoveWorkspaceComponent))