import { memo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { parseGraphqlError } from 'utils'
import { parseHtmlIntl } from 'ts-utils'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { DELETE_SURVEY_WORKSPACE_GROUP } from 'gql/survey_workspace_group'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import { StyledDialog, StyledButton } from 'styled/Modal'
import { Props as ParentProps } from './More'

interface Props {
    title: ParentProps['title'];
    survey_workspace_group_no: ParentProps['survey_workspace_group_no'];
    open: boolean;
    refetch: ParentProps['refetch'];
    onClose: () => void;
    onChange: ParentProps['onChange'];
}

let timer0: ReturnType<typeof setTimeout>
let timer1: ReturnType<typeof setTimeout>

function DeleteDialogComponent(props: Props) {
    const { title, survey_workspace_group_no, open, refetch, onClose, onChange } = props

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const [ Delete ] = useMutation(DELETE_SURVEY_WORKSPACE_GROUP, {
        onCompleted: (data) => {
            clearTimeout(timer0)
            clearTimeout(timer1)
            timer0 = setTimeout(() => {
                refetch()
            }, 100)
            timer1 = setTimeout(() => {
                onChange(data.deleteSurveyWorkspaceGroup)
                onClose()
                dispatch({ type: BACKDROPHIDE })
            }, 200)
        },
        onError: (e) => {
            if (parseGraphqlError(e) === 'have workspace') {
                dispatch({ type: BACKDROPHIDE })
                dispatch({ 
                    type: ALERTSNACKBARSHOW, 
                    variant: 'error', 
                    message: f({id: 'component.Survey.Workspace.Left.Group.DeleteDialog.error.0'}), 
                    duration: 6000 
                })
                onClose()
                return
            }

            if (parseGraphqlError(e) === 'one') {
                dispatch({ type: BACKDROPHIDE })
                dispatch({ 
                    type: ALERTSNACKBARSHOW, 
                    variant: 'error', 
                    message: f({id: 'component.Survey.Workspace.Left.Group.DeleteDialog.error.1'}), 
                    duration: 5000 
                })
                onClose()
                return
            }

            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
            onClose()
        }
    })

    const handleSave = () => {
        dispatch({ type: BACKDROPSHOW })
        Delete({
            variables: {
                survey_workspace_group_no
            }
        })
    }

    return (
        <StyledDialog open={open} onClose={onClose} scroll={'paper'} fullWidth>
            <DialogTitle>{f({id: 'component.Survey.Workspace.Left.Group.DeleteDialog.title'})}</DialogTitle>            
            <DialogContent>
                <Typography>{parseHtmlIntl(f({id: 'component.Survey.Workspace.Left.Group.DeleteDialog.content'}, {title}))}</Typography>
            </DialogContent>
            <DialogActions>
                <StyledButton onClick={handleSave} color="secondary">
                    {f({id: 'component.Survey.Workspace.Left.Group.DeleteDialog.button.remove'})}
                </StyledButton>
                <StyledButton onClick={onClose} color="default">
                    {f({id: 'component.Survey.Workspace.Left.Group.DeleteDialog.button.close'})}
                </StyledButton>
            </DialogActions>
        </StyledDialog>
        )

}

export default memo(DeleteDialogComponent)