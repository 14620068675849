import { memo } from 'react'
import { useIntl } from 'react-intl'
import { Draggable } from 'react-beautiful-dnd'
import { SurveyWorkspaceProps } from 'gql/survey_workspace'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import OpenWithIcon from '@material-ui/icons/OpenWith'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { getTitle } from './Lists'

const Item = withStyles({
    root: {
        textOverflow: 'ellipsis',
        width: '100%'
    }
})(ListItem)

interface Props {
    survey_workspace_no: SurveyWorkspaceProps['survey_workspace_no'];
    title: SurveyWorkspaceProps['title'];
    base: SurveyWorkspaceProps['base'];
    mbox_count: SurveyWorkspaceProps['mbox_count'];
    index: number;
    selected: boolean;
    onChange: (a: number) => void;
}

function ListItemComponent(props: Props) {
    const { survey_workspace_no, title, base, mbox_count, index, selected, onChange } = props

    const { formatMessage: f } = useIntl()

    return (
        <Draggable draggableId={`draggableId-${survey_workspace_no}`} index={index}>
        {
            (provided, snapshot) => {
                return (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <Item 
                        button 
                        selected={selected} 
                        onClick={() => onChange(survey_workspace_no)}
                    >
                        <ListItemText>
                            <Typography noWrap>{getTitle(f, { title, base })}</Typography>
                        </ListItemText>
                        <ListItemSecondaryAction>
                            {
                                (snapshot.isDragging) ? (
                                    <OpenWithIcon fontSize='small' color="action"/>
                                ) : (
                                    <Typography variant="caption" color="textSecondary">
                                        {mbox_count}
                                    </Typography>
                                )
                            }
                        </ListItemSecondaryAction>
                    </Item>
                    </div>
                )
            }
        }
        </Draggable>
    )
}

export default memo(ListItemComponent)