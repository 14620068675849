import { useState, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    TextField, 
    Typography, 
    FormControl,
    Select,
    MenuItem
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done'
import oc from 'open-color-js'
import { withBreakPoints } from 'hoc'
import { getMaxlength } from 'policy'
import { parseHtmlIntl } from 'ts-utils'

const useStyles = makeStyles(theme => ({
    form: {
        marginTop: theme.spacing(3)
    },
    helper: {
        display: 'flex',
        alignItems: 'center',
        color: oc.gray7,
        marginTop: 5,
        '& > .icon': {
            marginRight: 5
        },
        '& > .text': {
            paddingTop: 4,
            fontSize: 16
        },
        [theme.breakpoints.down('xs')]: {
            '& > .icon': {
                display: 'none'
            },
            '& > .text': {
                paddingTop: 4,
                fontSize: 14
            }
        }
    },
    tip: {
        marginTop: theme.spacing(3)
    } 
}))

const Component = props => {
    const { open, onCreate, onClose, breakpoints } = props
    const { xs } = breakpoints

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const [ title, setTitle ] = useState('')
    const [ method, setMethod ] = useState('one') // one, multi

    const changeTitle = useCallback((e) => {
        const { value } = e.target
        setTitle(value)
    }, [])

    const changeMethod = useCallback((e) => {
        const { value } = e.target
        setMethod(value)
    }, [])

    return (
        <Dialog open={open} onClose={onClose} fullWidth fullScreen={xs}>
            <DialogTitle id="form-dialog-title">{f({id: 'component.Survey.Workspace.Body.CreateSurvey.title'})}</DialogTitle>
            <DialogContent>
                {
                    useMemo(() => (
                        <TextField 
                            autoFocus 
                            margin="dense" 
                            label={f({id: 'component.Survey.Workspace.Body.CreateSurvey.textField'})} 
                            fullWidth 
                            onChange={changeTitle}
                            value={title}
                            inputProps={{
                                maxLength: getMaxlength('component.Survey.Create.Step.title')
                            }}
                        />
                    ), [f, title, changeTitle])
                }
                {
                    useMemo(() => (
                        <FormControl fullWidth className={classes.form}>
                            <Select value={method} onChange={changeMethod}>
                                <MenuItem value='one'>{<Typography variant="body2">{f({id: 'component.Survey.Workspace.Body.CreateSurvey.method.menuItem.0'})}</Typography>}</MenuItem>
                                <MenuItem value='multi'>{<Typography variant="body2">{f({id: 'component.Survey.Workspace.Body.CreateSurvey.method.menuItem.1'})}</Typography>} </MenuItem>
                            </Select>
                            <div className={classes.helper}>
                                <DoneIcon color="secondary" className="icon"/>
                                <Typography className="text" comnponent="span">
                                {
                                    method === 'one' ? parseHtmlIntl(f({id: 'component.Survey.Workspace.Body.CreateSurvey.method.helper.one.0'}))
                                                    : parseHtmlIntl(f({id: 'component.Survey.Workspace.Body.CreateSurvey.method.helper.multi'}))
                                }
                                </Typography>
                            </div>
                        </FormControl>
                    ), [f, method, changeMethod, classes.form, classes.helper])
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCreate({title, method})} color="primary" disabled={!title}>
                    <Typography variant="button">{f({id: 'component.Survey.Workspace.Body.CreateSurvey.button.0'})}</Typography>
                </Button>
                <Button onClick={onClose} color="default">
                    <Typography variant="button">{f({id: 'component.Survey.Workspace.Body.CreateSurvey.button.1'})}</Typography>
                </Button>
            </DialogActions>
        </Dialog> 
    )
}

export default withBreakPoints(Component)