import { useState, useCallback, createContext } from 'react'
import { useIntl } from 'react-intl'
import { useLazyQuery } from '@apollo/client'
import { Card, Button, Typography } from '@material-ui/core'
import { GET_SURVEY_MBOX_COUNT_IN_WORKSPACES } from 'gql/survey_workspace'
import { Add as AddIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import SelectCreateBox from './SelectCreateBox'
import LimitDialogComponent from './LimitDialog'
import useTokenInfo from 'hooks/useTokenInfo'
// import useCheckCreateSurvey from 'hooks/useCheckCreateSurvey'

interface Props {
    survey_workspace_no: number;
    mbox_count: number;
    refetchSurveyWorkspaces: () => void;
    onChangeOpenCreateSurvey: (a: boolean) => void;
}

const useStyles = makeStyles(theme => ({
    add: {
        height: 180,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        cursor: 'pointer'
    }
}))

export type OnOpen = () => void
export type OnCreate = () => void

export const AddContext = createContext({
    survey_workspace_no: 0
})

const isExpand = [ 2205, 2282 ]
function AddComponent(props: Props) {
    const [ open, setOpen ] = useState(false)

    const [ limitOpen, setLimitOpen ] = useState(false)

    const token = useTokenInfo()

    const { survey_workspace_no, mbox_count, refetchSurveyWorkspaces, onChangeOpenCreateSurvey } = props

    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    const limit = token ? isExpand.includes(token.user_no) ? 129 : 99 : 0

    const [ getCount ] = useLazyQuery(GET_SURVEY_MBOX_COUNT_IN_WORKSPACES, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.surveyMboxCountInWorkspace >= limit) {
                setLimitOpen(true)

                // 설문 갯수가 맞지 않으면 리패치
                if (data.surveyMboxCountInWorkspace !== mbox_count) refetchSurveyWorkspaces()

                return
            }

            setOpen(true)
        }
    })

    /* 
    const [ onCheckCreateSurvey ] = useCheckCreateSurvey('cache-first')
    */

    const handleOpen = useCallback(() => {
        getCount({
            variables: { survey_workspace_no }
        })
    }, [survey_workspace_no, getCount])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const handleLimitClose = useCallback(() => {
        setLimitOpen(false)
    }, [])

    const handleCreate = useCallback(() => {
        // const result = onCheckCreateSurvey()
        const result = 1
        
        handleClose()
        
        if (result) {
            onChangeOpenCreateSurvey(true)   
        }
    }, [handleClose, onChangeOpenCreateSurvey])

    return (
        <>
        <Card className={classes.add} variant="outlined" onClick={handleOpen}>
            <Button size='large' startIcon={<AddIcon/>}>
                {f({id: 'component.Survey.Workspace.Body.Add.button.add'})}
            </Button>
            <Typography color="textSecondary" variant="body2">
                {`(${mbox_count} / ${limit})`}
            </Typography>
        </Card>
        <AddContext.Provider value={{survey_workspace_no}}>
            <SelectCreateBox open={open} onClose={handleClose} onCreate={handleCreate}/>
        </AddContext.Provider>
        <LimitDialogComponent open={limitOpen} limit={limit} onClose={handleLimitClose} />
        </>
    )
}

export default AddComponent