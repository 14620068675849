import { memo } from 'react'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import { ItemsProp } from './Component'
import ItemsComponent from './Items'
import MoreComponent from './More'
import { Props } from './Component'

export type Items = ItemsProp[];

const MyBox = withStyles(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        '& > div:first-child': {
            flexGrow: 1,
            flexBasis: 0,
            width: 'calc(100% - 40px)'
        },
        '& > div:last-child': {
         
            width: 35
        }
    }
}))(Box)

function DataComponent(props: Props) {
    const { items, refetch, survey_workspace_group_no: selectedNo, onChange } = props

    const selectedValue = items.find(c => c.survey_workspace_group_no === selectedNo)

    const { title, survey_workspace_group_no } = selectedValue || { title: '', survey_workspace_group_no: 0 }

    const itemLength = items.length

    return (
        <MyBox>
            <div>
                <ItemsComponent items={items} selected={survey_workspace_group_no} title={title} refetch={refetch} onChange={onChange}/>
            </div>
            <div>
                <MoreComponent itemLength={itemLength} survey_workspace_group_no={survey_workspace_group_no} title={title} refetch={refetch} onChange={onChange}/>
            </div>
        </MyBox>
    )
}

export default memo(DataComponent)