import { useDispatch } from 'react-redux'
import { useQuery } from '@apollo/client'
import { useIntl } from 'react-intl'
import { SurveyAnalysisRowsProps } from 'gql/survey_analysis_rows'
import { GET_SURVEY_QUESTIONS_MODULE_ALL } from 'gql/survey_question_modules'
import { Mode } from 'gql/survey_analysis_summary'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import Button, { ButtonProps } from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import { Do } from 'component/Survey/Create/NewAnalysis/Rows/Personal'
import { ConditionContextProps } from './Items'

interface Props {
    data: SurveyAnalysisRowsProps;
    survey_no: number;
    isAnswerd: ConditionContextProps['personal']['isAnswerd'];
    title: string;
}

export const MyButton = withStyles({
    root: {
        backgroundColor: 'white'
    }
})((props: ButtonProps) => <Button {...props} variant="outlined" size="small"/>)

function DownloadPersonalComponent(props: Props) {
    const { data, survey_no, isAnswerd, title } = props

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const { data: questionData } = useQuery(GET_SURVEY_QUESTIONS_MODULE_ALL, {
        variables: { survey_no, mode: Mode.Live },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-first'
    })

    async function download() {
        if (!questionData) return
        dispatch({ type: BACKDROPSHOW, msg: f({id: 'component.Survey.Create.Analysis.Summary.DataDownloadDialog.downloadMsg'}) })
        setTimeout(async() => {

            await Do({f, questions: questionData.surveyQuestionsModuleAll, verifyUsed: isAnswerd, data, title })
            dispatch({ type: BACKDROPHIDE })
        }, 100)
    }

    return (
        <MyButton 
            color="secondary"
            startIcon={!questionData ? <CircularProgress size={10}/> : null} 
            onClick={download}
        >
            {f({id: 'component.Survey.Workspace.Left.Utility.Download.DownloadPersonal.button'})}
        </MyButton>
    )    
}

export default DownloadPersonalComponent