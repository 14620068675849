import { memo } from 'react'
import { useIntl } from 'react-intl'
import { Draggable } from 'react-beautiful-dnd'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { ItemsProp } from './Component'
import { OnChange, getDefaultWorkspaceGroupName } from './Items'

interface Props {
    i: number;
    survey_workspace_group_no: ItemsProp['survey_workspace_group_no'];
    title: ItemsProp['title'];
    selected: boolean;
    onChange: OnChange;
}

function ItemComponent(props: Props) {
    const { i, survey_workspace_group_no, title, selected, onChange } = props

    const { formatMessage: f } = useIntl()
    
    const newTitle = getDefaultWorkspaceGroupName(f, title)
    
    return (
        <Draggable draggableId={`group-item-${survey_workspace_group_no}`} index={i}>
            {(provided, snapshot) => {
                return (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                        <MenuItem style={{minWidth: 150}} selected={selected} onClick={() => onChange(survey_workspace_group_no)}>
                        <div  {...provided.dragHandleProps}>
                            <ListItemIcon onClick={(e) => e.stopPropagation()}>
                                <DragIndicatorIcon color="action" style={{width: 15, marginTop: 2}}/>
                            </ListItemIcon>
                        </div>
                        <Typography variant="inherit">{newTitle}</Typography>
                        </MenuItem>
                    </div>
                )
            }}
        </Draggable>
    )
}

export default memo(ItemComponent)