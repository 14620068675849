import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { useIntl } from 'react-intl'

const useStyles = makeStyles(theme => ({
    root: {
       marginTop: 30
    },
    formControl: {
        minWidth: 120,
        marginLeft: 10
    },
    select: {
        width: 200
    }
}))

export default () => {
    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    return (
        <div className={classes.root}>
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id='demo-simple-select-required-label' f='label'>{f({id: 'component.Survey.Workspace.Select.label'})}</InputLabel>
            <Select labelId='demo-simple-select-required-label' value={10} labelWidth={120} className={classes.select}>
                <MenuItem value="" f='item'>
                    <em>{f({id: 'component.Survey.Workspace.Select.item.0'})}</em>
                </MenuItem>
                <MenuItem value={10} f='item'>{f({id: 'component.Survey.Workspace.Select.item.1'})}</MenuItem>
                <MenuItem value={20} f='item'>{f({id: 'component.Survey.Workspace.Select.item.2'})}</MenuItem>
                <MenuItem value={30} f='item'>{f({id: 'component.Survey.Workspace.Select.item.3'})}</MenuItem>
                <MenuItem value={40} f='item'>{f({id: 'component.Survey.Workspace.Select.item.4'})}</MenuItem>
            </Select>
        </FormControl>
        </div>
    )
}