import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'
import { useIntl } from 'react-intl'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { withBreakPoints } from 'hoc'
import { HIDE as POPOVERHIDE } from 'reducer/popover'

const Component = props => {
    const { open, survey_no, onRemove, onClose, breakpoints } = props
    const { xs } = breakpoints

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const content = f({id: 'component.Survey.Workspace.Body.RemoveSurvey.content'}).replace(/\n/g, `<br />`)

    const handleRemove = useCallback(async() => {
        await dispatch({type: POPOVERHIDE})
        onRemove(survey_no)
    }, [dispatch, survey_no, onRemove])


    return (
        <Dialog open={open} onClose={onClose} fullWidth fullScreen={xs}>
            <DialogTitle>{f({id: 'component.Survey.Workspace.Body.RemoveSurvey.title'})}</DialogTitle>
            <DialogContent>
                <Typography>{ReactHtmlParser(content)}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleRemove} color="secondary">
                    <Typography variant="button">{f({id: 'component.Survey.Workspace.Body.RemoveSurvey.button.0'})}</Typography>
                </Button>
                <Button onClick={onClose} color="default">
                    <Typography variant="button">{f({id: 'component.Survey.Workspace.Body.RemoveSurvey.button.1'})}</Typography>
              </Button>
            </DialogActions>
        </Dialog> 
    )
}

export default withBreakPoints(Component)