import { memo } from 'react'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'
import { Collapse as CollapseProps, OnOpenCollapse, OnCloseCollapse } from './Items'

interface Props {
    collapse: CollapseProps;
    onOpen: OnOpenCollapse;
    onClose: OnCloseCollapse;
    breakpoints: BreakPointsProps;
}

const ButtonBox = withStyles(theme => ({
    root: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            background: oc.gray[2],
            width: '100%',
        }
    }
}))(Box)

function ItemsComponent(props: Props) {
    const { collapse, breakpoints, onOpen, onClose } = props
    const { xs } = breakpoints

    const { formatMessage: f } = useIntl()

    // 모바일 이면서 검색창이 열린 상태면 해당 버튼 안보여준다
    if (xs && collapse) return null

    return (
        <ButtonBox>
            <Button fullWidth style={{height: 35}} color="primary" onClick={() => collapse ? onClose() : onOpen()}>
                {collapse ? f({id: 'component.Survey.Workspace.Left.Utility.Download.SearchGrid.button.0'}) : f({id: 'component.Survey.Workspace.Left.Utility.Download.SearchGrid.button.1'})}
            </Button>            
        </ButtonBox>
    )
}

export default memo(withBreakPoints(ItemsComponent))