import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { HIDE as POPOVERHIDE } from 'reducer/popover'
import { List, ListItem, ListItemText } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
// import useCheckCreateSurvey from 'hooks/useCheckCreateSurvey'

const SecondaryListItemText = withStyles(theme => ({
    root: {
        color: theme.palette.secondary.main
    }
}))(ListItemText)

export default (props) => {
    const { survey_no, title, onLocation, onChangeOpenPreview, onChangeOpenUodateSurveyTitle, onChangeOpenRemoveSurvey, onChangeOpenCopySurvey, onChangeOpenMoveSurvey } = props

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    // const [ handleCheckCreateSurvey ] = useCheckCreateSurvey('cache-first')

    const handleLocation = useCallback(async(type) => {
        onLocation({type, survey_no})
    }, [survey_no, onLocation])

    function handleCopy() {
        // const result = handleCheckCreateSurvey()
        const result = 1
        if (result) onChangeOpenCopySurvey({open: true, survey_no})
    }

    const handleClose = () => {
        dispatch({type: POPOVERHIDE})
    }

    return (
        <List dense>
            <ListItem button onClick={() => {
                handleClose()
                onChangeOpenPreview(survey_no)
            }}>
                <ListItemText primary={f({id: 'component.Survey.Workspace.Body.ListItemMore.lists.0'})}/>
            </ListItem>
            <ListItem button onClick={() => {
                handleClose()
                onChangeOpenUodateSurveyTitle({open: true, survey_no, title})
            }}>
                <ListItemText primary={f({id: 'component.Survey.Workspace.Body.ListItemMore.lists.1'})}/>
            </ListItem>
            <ListItem button onClick={() => {
                handleClose()
                handleLocation('create')
            }}>
                <ListItemText primary={f({id: 'component.Survey.Workspace.Body.ListItemMore.lists.2'})}/>
            </ListItem>
            <ListItem button onClick={() => {
                handleClose()
                handleLocation('share')
            }}>
                <ListItemText primary={f({id: 'component.Survey.Workspace.Body.ListItemMore.lists.3'})}/>
            </ListItem>
            <ListItem button onClick={() => {
                handleClose()
                handleLocation('analysis')
            }}>
                <ListItemText primary={f({id: 'component.Survey.Workspace.Body.ListItemMore.lists.4'})}/>
            </ListItem>
            <ListItem button onClick={() => {
                handleClose()
                handleCopy()
            }}>
                <ListItemText primary={f({id: 'component.Survey.Workspace.Body.ListItemMore.lists.5'})}/>
            </ListItem>
            <ListItem button onClick={() => {
                handleClose()
                onChangeOpenMoveSurvey({open: true, survey_no})
            }}>
                <ListItemText primary={f({id: 'component.Survey.Workspace.Body.ListItemMore.lists.6'})}/>
            </ListItem>
            <ListItem button onClick={() => {
                handleClose()
                onChangeOpenRemoveSurvey({open: true, survey_no})
            }}>
                <SecondaryListItemText primary={f({id: 'component.Survey.Workspace.Body.ListItemMore.lists.7'})}/>
            </ListItem>
        </List>
    )
}