import { useCallback, useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { GET_BUG_REPORT, CREATE_BUG_REPORT } from 'gql/log'
import { List, ListSubheader, IconButton, Collapse } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { makeStyles } from '@material-ui/core/styles'
import oc from 'open-color'
import { useIntl } from 'react-intl'
import ItemUpdate from './ItemUpdate'
import ItemBug from './ItemBug'
import ItemHelp from './ItemHelp'
import ItemPrice from './ItemPrice'
import ItemChat from './ItemChat'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: 'white',
        borderTop: `1px solid ${oc.gray[3]}`
    },
    subheader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& > div:last-child': {
            display: 'inline-flex',
            flexDirection: 'row',
            '& > * + *': {
                marginLeft: 8
            }
        },
        cursor: 'pointer'
    }
}))

let timer: ReturnType<typeof setTimeout>

const Component = () => {
    const classes = useStyles()
    
    const { formatMessage: f } = useIntl()

    const [ open, setOpen ] = useState(false)

    const { data, refetch } = useQuery(GET_BUG_REPORT, {
        fetchPolicy: 'cache-first'
    })

    const [ createBugReport ] = useMutation(CREATE_BUG_REPORT, {
        onCompleted: () => {
            refetch()
        }
    })

    const handleMenuOpenToggle = useCallback(() => {
        setOpen(prevState => !prevState)
    }, [])

    const handleClick = () => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            createBugReport()
        }, 500)
    }

    useEffect(() => {
        if (data) {
            // 버그 리포트 안 읽었다면 열어주세요
            // if (!data.bugReport) setOpen(true)
        }
    }, [data])
    return (
        
        <List
            dense 
            component="div" 
            aria-labelledby="category-left" 
            subheader={
                <ListSubheader component="div" className={classes.subheader} onClick={handleMenuOpenToggle}>
                    <div>{f({id: 'component.Survey.Workspace.Left.Help.title'})}</div>
                    <div>
                        <IconButton size="small">
                            { open ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                        </IconButton>
                    </div>
                </ListSubheader>
            }
            className={classes.root}
            onClick={handleClick}
        >
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List dense component="div" disablePadding>
                    <ItemHelp/>
                    <ItemUpdate/>
                    <ItemBug isRead={!data ? true : data.bugReport ? true: false}/>
                    <ItemChat/>
                    <ItemPrice/>
                </List>
            </Collapse>
        </List>
    )
}

export default Component