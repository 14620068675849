/**
 * 응답 수 보여주는 컴포넌트
 */
import { memo } from 'react'
import { useIntl } from 'react-intl'
import { numberFormat } from 'utils'
import { SurveyAnalysisEndResponseProps } from 'gql/survey_analysis_users'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from 'styled/Custom/Tooltip'
import { Props as ParentProps } from './ListItemResponseCount'

interface Props {
    survey_no: number;
    loading: boolean;
    today_end_count: SurveyAnalysisEndResponseProps['today_end_count'];
    end_count:  SurveyAnalysisEndResponseProps['end_count'];
    onLocation: ParentProps['onLocation'];
}

const useStyles = makeStyles(theme => ({
    badge: {
        position: 'absolute',
        top: 0,
        right: 0,
        height: 21,
        fontSize: 12,
        borderRadius: 3,
        boderTopLeftRadius: 0,
        boderBottomRightRadius: 0,
        zIndex: 1,
        width: 65,
        textAlign: 'center',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center'
    },
    badgeText: (props: { today_end_count: number }) => {
        const { today_end_count } = props

        return {
            background: today_end_count > 0 ? theme.palette.secondary.main : theme.palette.grey[400],
            color: today_end_count > 0 ? 'white' : theme.palette.grey[900],
            fontWeight: 700,
            height: '100%',
            flexGrow: 1,
            paddingLeft: 5,
            paddingRight: 5,
            borderTopRightRadius: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }
}))

function ListItemResponseCountTextComponent(props: Props) {
    const { survey_no, today_end_count, end_count, onLocation } = props

    const classes = useStyles({  today_end_count })

    const { formatMessage: f } = useIntl()

    let count: string = '0'
    let title = ''

    // 오늘 응답수가 없다면
    if (today_end_count > 0) {
        count = `+ ${numberFormat(today_end_count)}`
        title = f({id: 'component.Survey.Workspace.Body.ListItem.today_end_count'})
    } else {
        count = numberFormat(end_count)
        title = f({id: 'component.Survey.Workspace.Body.ListItem.end_count'})
    }

    return (
        <Tooltip title={title}>
            <div className={classes.badge}>
                <Typography 
                    variant="caption" 
                    className={classes.badgeText}
                    onClick={() => onLocation({type: 'analysis', hash: 'tab=rows', survey_no})}
                >
                    {count}
                </Typography>
            </div>
        </Tooltip>
    )
} 

export default memo(ListItemResponseCountTextComponent)