import { forwardRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import ReactIframeComponent from 'styled/ReactIframe'

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 4.6em)',
        paddingTop: 40,
        paddingBottom: 40,
        [theme.breakpoints.down('md')]: {
            paddingTop: 20,
            paddingBottom: 20
        },
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 4.6em)'
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 20,
            paddingBottom: 15
        }
    }
}))

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function FullScreenDialog(props) {
    const { open, title, src, onClose } = props

    const classes = useStyles()
    
    return (
        <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Container className={classes.container}>
                <ReactIframeComponent src={src} style={{ border: 'none' }}/>
            </Container>
      </Dialog>
    )
}
