import styled from 'styled-components'
import AddComponent from './Add'

const WrapperStyled = styled.div`
    display: flex;
    margin-top: 8px;
    padding-bottom: 1em;
    flex-wrap: wrap;
    --gap: 1.5em;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));

    & > * {
        --rows: 5;

        @media only screen and (max-width: 1280px) {
            --rows: 3;
        }

        @media only screen and (max-width: 600px) {
            --rows: 2;
        }

        margin: var(--gap) 0 0 var(--gap);
        width: calc(100% / var(--rows) - var(--gap));
    }
`

const Component = (props) => {
    const { survey_workspace_no, mbox_count, onChangeOpenCreateSurvey, refetchSurveyWorkspaces, children } = props

    return (
        <>
        <WrapperStyled>
            <AddComponent survey_workspace_no={survey_workspace_no} mbox_count={mbox_count} refetchSurveyWorkspaces={refetchSurveyWorkspaces} onChangeOpenCreateSurvey={onChangeOpenCreateSurvey}/>
            {children}
        </WrapperStyled>
        </>
    )
}

export default Component