import { memo, useCallback } from 'react'
import { ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import BugReportIcon from '@material-ui/icons/BugReport'
import FiberNewIcon from '@material-ui/icons/FiberNew'
import { useIntl } from 'react-intl'

interface Props {
    isRead: boolean;
}

function ItemBugComponent(props: Props) {
    const { isRead } = props

    const { formatMessage: f } = useIntl()

  
    const handleOpen = useCallback(() => {
        window.open('https://listovey-update.notion.site/05f6492806be4a759898fbf35883bedd?pvs=4', '_blank')
    }, [])

    return (
        <>
        <ListItem button onClick={handleOpen}>
            <ListItemIcon style={{position: 'relative'}} >
                <BugReportIcon fontSize="small"/>
            </ListItemIcon>
            <ListItemText>
                <Typography component="div" style={{position: 'relative', display: 'table', fontSize: 14}}>
                    {f({id: 'component.Survey.Workspace.Left.Help.ItemBug.title'})}
                    { 
                        !isRead && (
                            <FiberNewIcon color='secondary' fontSize="small" style={{position: 'absolute', zIndex: 5, top: 0, right: -22, width: 18}}/>
                        )
                    }
                </Typography>
            </ListItemText>
        </ListItem>
        </>
    )
}

export default memo(ItemBugComponent)