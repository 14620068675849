/**
 * 설문의 제출 수
 */

import { memo, useCallback, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducer'
import { CHANGE } from 'reducer/survey/workspace/responseCount'
import { GET_SURVEY_ANALYSIS_END_RESPONSE } from 'gql/survey_analysis_users'
import ListItemResponseCountButtonComponent from './ListItemResponseCountButton'
import ListItemResponseCountTextComponent from './ListItemResponseCountText'

export interface Props {
    survey_no: number;
    today_end_count: number;
    is_response: boolean;
    onLocation: (props: { type: string, hash: string, survey_no: number}) => void;
}

function ListItemResponseCountComponent(props: Props) {
    const { survey_no, today_end_count, is_response, onLocation } = props

    const dispatch = useDispatch()

    // 응답수를 가져온 이력이 있는지 판단
    const states = useSelector((states: RootState) => states.surveyWorkspaceResponseCount)
    const isFetch = Boolean(states.survey_nos.find(c => c === survey_no))

    // 응답 수 가져오기
    const [ getData, { data, loading } ] = useLazyQuery(GET_SURVEY_ANALYSIS_END_RESPONSE, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-first',
        onCompleted: () => {
            if (!isFetch) dispatch({ type: CHANGE, survey_no })
        }
    })

    const handleButtonClick = useCallback(() => {
        if (loading) return
        getData({
            variables: {
                survey_no
            }
        })
    }, [loading, survey_no, getData])

    // 오늘 값이 없고, 기존에 패치 받았던게 있다면 캐시로 가져온다.
    useEffect(() => {
        if (today_end_count === 0 && isFetch) {
            getData({
                variables: {
                    survey_no
                }
            })
        }
    }, [today_end_count, survey_no, isFetch, getData])

    // 접속값등 접속이력이 아예 없다면 빈값처리
    if (!is_response) return null

    // 오늘 응답수 없다면 전체 응답수 보여준다.
    let end_count = 0
    if (today_end_count === 0) {
        if (!data) return <ListItemResponseCountButtonComponent  loading={loading} onClick={handleButtonClick} />

        end_count = data.surveyAnalysisEndResponse
    }

    return (
        <ListItemResponseCountTextComponent 
            survey_no={survey_no} 
            loading={loading} 
            today_end_count={today_end_count} 
            end_count={end_count} 
            onLocation={onLocation} 
        />
    )
}

export default memo(ListItemResponseCountComponent)