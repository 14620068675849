import { memo, useState, useContext } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from 'react-redux'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { parseHtmlIntl } from "ts-utils"
import { SurveyWorkspaceProps } from 'gql/survey_workspace'
import PropsContext from 'context/props'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import GetAppIcon from '@material-ui/icons/GetApp'
import CloseIcon from "@material-ui/icons/Close"
import Dialog from "styled/Survey/Live/Dialog"
import Items from './Items'


function DownloadComponent() {
    const { workspaceRows, selected } = useContext<{ workspaceRows: SurveyWorkspaceProps[], selected: number }>(PropsContext)

    const [ open, setOpen ] = useState(false)

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    function handleOpen() {
        if (workspaceRows.length === 0) {
            dispatch({ 
                type: ALERTSNACKBARSHOW,
                message: f({id: 'component.Survey.Workspace.Left.Utility.Analysis.Component.error'}),
                duration: 5000
            })
            return
        }
        setOpen(true)
    }

    function handleClose() {
        setOpen(false)
    }
    return (
        <>
        <ListItem button onClick={handleOpen}>
            <ListItemIcon>
                <GetAppIcon color="primary" fontSize="small"/>
            </ListItemIcon>
            <ListItemText primary={f({id: 'component.Survey.Workspace.Left.Utility.Download.Component.title'})}/>
        </ListItem>
        <Dialog open={open} onClose={() => {}}>
            <AppBar style={{  position: 'relative',}}>
                <Toolbar>
                    <Typography variant="h6" style={{flex: 1}}>
                        {parseHtmlIntl(f({id: 'component.Survey.Workspace.Left.Utility.Download.Component.subtitle'}))}
                    </Typography>
                    <IconButton edge="end" color="inherit" onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent style={{padding: 0, marginBottom: 0}}>
                <Items  workspaceRows={workspaceRows} selected={selected}/>
            </DialogContent>
        </Dialog>
        </>
    )
}

export default memo(DownloadComponent)