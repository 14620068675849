import { useRef, useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import PropsContext from 'context/props'
import { UPDATE_SURVEY_WORKSPACE_SORTING } from 'gql/survey_workspace'
import { List, ListSubheader } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withPrevState } from 'hoc'
import { SurveyWorkspaceLeftHeadComponent } from 'component'
import ListItemComponent from './ListItem'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        overflowY: 'auto',
        height: '100%'
    }
}))

export function getTitle(f, c) {
    return (c.base && !c.title) ? f({id: 'component.Survey.Workspace.Left.Lists.defaultTitle'}) : c.title
}

const Component = (props) => {
    const { usePrevState } = props
    const { workspaceRows, selected, onChangeSelected } = useContext(PropsContext)
    const classes = useStyles()

    const refList = useRef()

    const [ rows, setRows ] = useState(workspaceRows)

    const [ updateSorting ] = useMutation(UPDATE_SURVEY_WORKSPACE_SORTING, {
        onCompleted: () => {

        }
    })

    // lists length 
    const prevWorkspaceRowsLength = usePrevState(workspaceRows.length)
    const nextWorkspaceRowsLength = workspaceRows.length

    const handleDnD = (result) => {
        if (!result.destination) {
            return
        }

        // survey_question 복사
        const copy = [...rows]

        const sourceIndex = result.source.index //원래 인덱스
        const destinationIndex = result.destination.index
    
        // 옮겨져야할 값 을 배열에서 삭제하고 value값은 저장
        const [removedData] = copy.splice(sourceIndex, 1)
    
        // 옮겨져야할 값 추가
        copy.splice(destinationIndex, 0, removedData)
        
        setRows(copy)

        updateSorting({
            variables: {
                survey_workspace_nos: copy.map(c => c.survey_workspace_no)
            }
        })
    }

    useEffect(() => {
        // rows 길이가 전보다 길다면 추가한거다 최상단으로 포커스를 준다
        setRows(workspaceRows)
        if (nextWorkspaceRowsLength > prevWorkspaceRowsLength) {
        
            refList.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
    }, [workspaceRows, prevWorkspaceRowsLength, nextWorkspaceRowsLength])

    const len = rows.length

    return (
        <List
        ref={ref => refList.current = ref}
        component="nav" 
        aria-labelledby="category-left" 
        className={`${classes.root} scroll`}
        disablePadding
        dense={len >= 8 ? true : false}
        subheader={
            <ListSubheader component="div" disableGutters>
                <SurveyWorkspaceLeftHeadComponent/>
            </ListSubheader>
        }
    >   
        <DragDropContext onDragEnd={handleDnD} >
            <Droppable droppableId='droppable'>
          
            {
                (provided, snapshot) => (
                    <div ref={provided.innerRef}  {...provided.droppableProps}> 
                 
                        {
                            rows.map((c, i) => (
                                <ListItemComponent
                                    key={c.survey_workspace_no} 
                                    survey_workspace_no={c.survey_workspace_no} 
                                    index={i}
                                    selected={selected === c.survey_workspace_no} 
                                    title={c.title}
                                    base={c.base}
                                    mbox_count={c.mbox_count}
                                    onChange={onChangeSelected}

                                />
                            ))
                        }
                        {
                            snapshot.isDraggingOver && <div style={{height: 50}}/>
                        }
                    </div>
                    
                )
            }
          
            </Droppable>
        </DragDropContext>
        </List>
    )
}

export default withPrevState(Component)