import { useContext, useEffect, useState, lazy, Suspense } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import { useIntl } from 'react-intl'
import { GET_SURVEY_ANALYSIS_ROWS, SurveyAnalysisRowsVariables, SurveyAnalysisRowsProps } from 'gql/survey_analysis_rows'
import { Mode } from 'gql/survey_analysis_summary'
import { CHANGE as INGCHANGE, INIT as INGINIT } from 'reducer/survey/workspace/left/utility/download/ing'
import { RootState } from 'reducer'
import { parseGraphqlError } from 'utils'
import oc from 'open-color'
import { isEqual } from 'lodash'
import IconButton from '@material-ui/core/IconButton'
import GetAppIcon from '@material-ui/icons/GetApp'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from 'styled/Custom/Tooltip'
import { getDate } from 'styled/Survey/Analysis/Calendar'
import BeforeUnload from 'styled/BeforeUnload'
import useRowsDownloadError from 'hooks/useRowsDownloadError'
import { ConditionContext, YearMember } from './Items'
import { parseDate } from 'container/Survey/Create/NewAnalysis/Box'

const DownloadPersonalComponent = lazy(() => import('./DownloadPersonal'))
const DownloadRowsComponent = lazy(() => import('./DownloadRows'))

interface Props {
    survey_no: number;
    is_response: boolean;
    title: string;
    yearMember: YearMember;
}

function DownloadButtonComponent(props: Props) {
    const { survey_no, is_response, title, yearMember } = props
    
    const { step, date, rows, personal } = useContext(ConditionContext)
    const { answerType, fileType } = rows
    const { isAnswerd } = personal

    const { formatMessage: f } = useIntl()

    // date값 기준으로 현재 날짜 가져온다.
    const { sdate, edate } = getDate(date)

    const dispatch = useDispatch()

    const [ onError ] = useRowsDownloadError()

    const disabled = !is_response || !yearMember

    const [ prevState, setPrevState ] = useState({ step, sdate, edate })

    // 다운로딩중
    const ing = useSelector((state: RootState) => state.surveyWorkspaceLeftUtilityDownloadIng)
    let isIng = false
    if (ing.survey_no && ing.survey_no !== survey_no) isIng = true

    // 다운로드 중에는 다른 다운로드 못하게 막는다
    const ingStyle = (isIng && !disabled) ? { color: oc.gray[6], cursor: 'default' } : {}

    let tooltip = ''
    if (!yearMember) tooltip = f({id: 'component.Survey.Workspace.Left.Utility.Download.DownloadButton.tooltip.yearDate'})
    if (isIng && !disabled) tooltip = f({id: 'component.Survey.Workspace.Left.Utility.Download.DownloadButton.tooltip.isIng'})
    
    const [getData, { data, loading }] = useLazyQuery<{surveyAnalysisRows: SurveyAnalysisRowsProps}, SurveyAnalysisRowsVariables>(GET_SURVEY_ANALYSIS_ROWS, {
        fetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
        onError: (e) => {
            // 에러면 다운로드 끝난거다 빈값을 넣어준다.
            
            dispatch({ type: INGCHANGE, survey_no: '' })
            if (parseGraphqlError(e) === 'limit') {
                onError()
            }
        }
    })

    function handleGetData() {
        if (isIng) return

        setPrevState({ step, sdate, edate })

        dispatch({ type: INGCHANGE, survey_no })
        getData({
            variables: {
                survey_no, 
                mode: Mode.Live,
                input: {
                    step: Number(step),
                    date: parseDate(sdate, edate), 
                    search: null
                }
            }
        })
    }

    // 다운로드가 다 되면 다시 다운로드 가능하게 처리
    useEffect(() => {
        if (data) {
            setTimeout(() => {
                dispatch({ type: INGINIT })
            }, 1000)
        }
    }, [data, dispatch])

    if (loading) {
        return (
            <CircularProgress color="primary" size={30}/>
        )
    }

    return (
        <>
        {
            (!data || !isEqual(prevState, { step, sdate, edate })) ? (
                <Tooltip title={tooltip} isMobileClick={true}>
                    <span>
                        <IconButton disabled={disabled} size="small" style={ingStyle} color="primary" onClick={handleGetData}>
                            <GetAppIcon/>
                        </IconButton>
                    </span>
                </Tooltip>

            ) : (
                <Suspense fallback="">
                    <div style={{display: 'flex', alignItems: 'center', gap: 2}}>
                    <DownloadPersonalComponent survey_no={survey_no} isAnswerd={isAnswerd} data={data.surveyAnalysisRows} title={title}/>
                    <DownloadRowsComponent answerType={answerType} fileType={fileType} data={data.surveyAnalysisRows} title={title}/>
                    <BeforeUnload/>
                    </div>
                </Suspense>
            )
          
        }
        </>
       
    )
}

export default DownloadButtonComponent