import { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    TextField, 
    Typography
} from '@material-ui/core'
import { withBreakPoints } from 'hoc'
import { getMaxlength } from 'policy'
import { HIDE as POPOVERHIDE } from 'reducer/popover'

const Component = props => {
    const { open, survey_no, title, onUpdate, onClose, breakpoints } = props
    const { xs } = breakpoints

    const { formatMessage: f } = useIntl()

    const [ _title, _setTitle ] = useState('')

    const dispatch = useDispatch()
    
    const changeTitle = useCallback((e) => {
        const { value } = e.target
        _setTitle(value)
    }, [])

    const handleUpdate = useCallback(() => {
        onUpdate({survey_no, title: _title})
        dispatch({type: POPOVERHIDE})
    }, [dispatch, _title, survey_no, onUpdate])

    useEffect(() => {
        _setTitle(title)
    }, [title])

    return (
        <Dialog open={open} onClose={onClose} fullWidth fullScreen={xs}>
            <DialogTitle id="form-dialog-title">{f({id: 'component.Survey.Workspace.Body.UpdateSurveyTitle.title'})}</DialogTitle>
            <DialogContent>
                <TextField 
                    autoFocus 
                    margin="dense" 
                    label={f({id: 'component.Survey.Workspace.Body.CreateSurvey.textField'})} 
                    fullWidth 
                    onChange={changeTitle}
                    value={_title}
                    inputProps={{
                        maxLength: getMaxlength('component.Survey.Create.Step.title')
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleUpdate} color="primary" disabled={!_title}>
                    <Typography variant="button">{f({id: 'component.Survey.Workspace.Body.UpdateSurveyTitle.button.0'})}</Typography>
                </Button>
                <Button onClick={onClose} color="default">
                    <Typography variant="button">{f({id: 'component.Survey.Workspace.Body.UpdateSurveyTitle.button.1'})}</Typography>
                </Button>
            </DialogActions>
        </Dialog> 
    )
}

export default withBreakPoints(Component)