import { memo } from 'react'
import { useIntl } from 'react-intl'
import { InputAdornment, Button } from '@material-ui/core'
import { DeleteOutlineOutlined as DeleteOutlineOutlinedIcon } from '@material-ui/icons'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'

interface Props {
    onClick: (a: boolean) => void;
    breakpoints: BreakPointsProps
}

function DeleteWorkspaceComponent(props: Props) {
    const { breakpoints, onClick } = props
    const {xs } = breakpoints

    const { formatMessage: f } = useIntl()
    
    return (
        <InputAdornment position="end" onClick={() => onClick(true)}>
            {
                xs ? (
                    <DeleteOutlineOutlinedIcon color="action" style={{cursor: 'pointer'}}/>
                ) : (
                    <Button color="secondary" size="small">
                        {f({id: 'component.Survey.Workspace.Body.DeleteWorkspaceButton.DeleteWorkspaceButton.button.remove'})}
                    </Button>
                ) 
            }
        </InputAdornment>
    )
}

export default withBreakPoints(memo(DeleteWorkspaceComponent))