import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        background: 'white'
    }
}))

export default ({children}) => {

    const classes = useStyles()

    return (
        <Container maxWidth='lg' className={classes.root}>
            {children}
        </Container>
    )
}