/**
 * 그룹 in survey_workspace 리스트 가져오기
 */
import { memo, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_SURVEY_WORKSPACES_GROUP_BYS, SurveyWorkspacesGroupBys, SurveyWorkspaceGroupProps } from 'gql/survey_workspace_group'
import { SurveyWorkspaceProps } from 'gql/survey_workspace'
import CircularProgress from '@material-ui/core/CircularProgress'
import WorkspaceGroupBySelectComponent from './WorkspaceGroupBySelect'

export enum Type {
    Create,
    Move
}

export interface Props {
    type: Type;
    open: boolean;
    targetSurveyWorkspaceGroupNo: SurveyWorkspaceGroupProps['survey_workspace_group_no'];
    targetSurveyWorkspaceNo: SurveyWorkspaceProps['survey_workspace_no'];
    survey_workspace_no: SurveyWorkspaceProps['survey_workspace_no'] | '';
    onChangeWorkspaceGroup: (a: number) => void;
    onChangeWorkspace: (a: number | '') => void;
}

function WorkspaceGroupByData(props: Props) {
    const { type, open, targetSurveyWorkspaceGroupNo, targetSurveyWorkspaceNo, survey_workspace_no, onChangeWorkspaceGroup, onChangeWorkspace } = props
    const [ getData, { data, refetch }] = useLazyQuery<{ surveyWorkspacesGroupBys: SurveyWorkspacesGroupBys[] }>(GET_SURVEY_WORKSPACES_GROUP_BYS)

    useEffect(() => {
        if (open) {
            if (refetch) refetch()
            else getData()
        } 
    }, [open, getData, refetch])

    if (!data) return <CircularProgress size={18} color="primary"/>

    return (
        <WorkspaceGroupBySelectComponent 
            type={type}
            rows={data.surveyWorkspacesGroupBys}
            targetSurveyWorkspaceGroupNo={targetSurveyWorkspaceGroupNo}
            targetSurveyWorkspaceNo={targetSurveyWorkspaceNo}
            survey_workspace_no={survey_workspace_no}
            onChangeWorkspaceGroup={onChangeWorkspaceGroup}
            onChangeWorkspace={onChangeWorkspace}
        />
    )
}

export default memo(WorkspaceGroupByData)