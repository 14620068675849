import { memo, ReactNode } from 'react'
import oc from 'open-color'
import Grid from '@material-ui/core/Grid'
import Collapse from '@material-ui/core/Collapse'
import { withStyles } from '@material-ui/core/styles'
import { Collapse as CollapseProps, OnOpenCollapse, OnCloseCollapse } from './Items'
import SearchGridCollapseComponent from './SearchGridCollapse'

interface Props {
    collapse: CollapseProps;
    onOpen: OnOpenCollapse;
    onClose: OnCloseCollapse;
    children: ReactNode;
}

const GridItem0 = withStyles(theme => ({
    root: {
        background: 'white', 
        minHeight: 'calc(100vh - 64px)', 
        borderRight: `1px solid ${oc.gray[2]}`,
        [theme.breakpoints.down('sm')]: {
            borderBottom: `1px solid ${oc.gray[2]}`,
            borderRight: 'none',
            minHeight: 0,
            position: 'sticky', 
            top: 0, 
            zIndex: 1,

            overflowY: 'hidden'
        }
    }
}))(Grid)

const MyCollapse = withStyles(theme => ({
    root: {
        width: '100%',
        transition: 'all 3s',
        [theme.breakpoints.up('md')]: {
            position: 'sticky', 
            top: 0, 
            zIndex: 1
        }
    }
}))(Collapse)

function ItemsComponent(props: Props) {
    const { collapse, onOpen, onClose, children } = props

    return (
        <GridItem0 item xs={12} sm={12} md={4}>
            <MyCollapse in={collapse}>
                {children}
            </MyCollapse>
            <SearchGridCollapseComponent collapse={collapse} onOpen={onOpen} onClose={onClose}/>
        </GridItem0>
    )
}

export default memo(ItemsComponent)