import { memo, useContext } from 'react'
import { SurveyWorkspaceGroupProps } from 'gql/survey_workspace_group'
import CircularProgress from '@material-ui/core/CircularProgress'
import DataComponent from './Data'
import { GroupContext, GroupContextProps } from 'container/Survey/Workspace/Group'

export interface Props {
    items: SurveyWorkspaceGroupProps[];
    refetch: () => void;
    survey_workspace_group_no: GroupContextProps['survey_workspace_group_no'];
    onChange: GroupContextProps['onChange'];
}

export type ItemsProp = SurveyWorkspaceGroupProps

function GroupComponent() {
    const { data, refetch, survey_workspace_group_no, onChange } = useContext<GroupContextProps>(GroupContext)

    if (!data || !refetch) return <CircularProgress size={20}/>

    return (
        <DataComponent 
            items={data.surveyWorkspaceGroups} 
            refetch={refetch} 
            survey_workspace_group_no={survey_workspace_group_no} 
            onChange={onChange}
        />
    )
}

export default memo(GroupComponent)
