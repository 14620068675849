import { useContext } from 'react'
import { useIntl } from 'react-intl'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import withFetchSurveyTemplateCategory, { SurveyTemplateCategoryProps } from 'hoc/Survey/FetchTemplateCategory'
import PublishComponent from 'component/Outer/Template/Publish'
import { AddContext } from '../Add'

export interface ParentProps {
    data: SurveyTemplateCategoryProps['surveyTemplateCategory']['data']
    refetch: SurveyTemplateCategoryProps['surveyTemplateCategory']['refetch']
}

function TemplateComponent(props: SurveyTemplateCategoryProps) {
    const { surveyTemplateCategory } = props
    const { data } = surveyTemplateCategory

    const { formatMessage: f } = useIntl()

    const { survey_workspace_no } = useContext(AddContext)

    return (
        <Container maxWidth="lg">
            <Typography variant="h6" style={{marginBottom: 30, marginTop: 30}}>
                {f({id: 'component.Survey.Workspace.Body.Template.Content.title'})}
            </Typography>
            <Grid container spacing={2}>
                <PublishComponent data={data} edit={false} survey_workspace_no={survey_workspace_no}/>
            </Grid>
        </Container>
    )
}

export default withFetchSurveyTemplateCategory('cache-first')(false)(TemplateComponent)

