import { useIntl } from 'react-intl'
import { numberFormat } from 'utils'
import { parseHtmlIntl } from 'ts-utils'
import { TokenTypes } from 'hooks/useTokenInfo'
import moment from 'moment'
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/styles'

interface Props {
	responses: TokenTypes['responses'];
}

interface LinearProgressWithLabelProps extends LinearProgressProps {
	used: number;
	max: number;
	ldate: string;
}

const useLinearProgressWithLabelStyles = makeStyles({
    exp: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 0
    },
	progress: {
		height: 5, 
		borderRadius: 2,
		marginBottom: 2
	},
	box: {
		'& + &': {
			marginTop: 10
		}
	}
})

export const MyTitle = withStyles({
	root: {
		fontWeight: 700, 
		fontSize: 14
	}
})(props => <Typography gutterBottom {...props}/>)

function LinearProgressWithLabel({used, max, ldate, value, ...other}: LinearProgressWithLabelProps) {
	
	const classes = useLinearProgressWithLabelStyles()

	const { formatMessage: f } = useIntl()

    const Ldate = moment().format('YYYY-MM-DD') > ldate ? f({id: 'component.Survey.Workspace.Left.Info.Response.end'}) : `~${moment(ldate).format('YY.MM.DD')}`

	return (
        <div className={classes.box}>
            <LinearProgress variant="determinate" color="secondary" className={classes.progress} value={value} {...other}/>
            <Box className={classes.exp}>
                <Typography variant="body2" style={{paddingLeft: 2, fontSize: 12}}>{parseHtmlIntl(`<b>${numberFormat(used)}</b> / ${numberFormat(max)}`)}</Typography>
                <Typography variant="body2" style={{fontSize: 12}}>{Ldate}</Typography>                
            </Box>
        </div>
    )
}

function LinearWithValueLabelComponent(props: Props) {
	const { responses } = props

	const { formatMessage: f } = useIntl()

	if (!responses) return null
	
	return (
		<>
		<MyTitle >{f({id: 'component.Survey.Workspace.Left.Info.Response.title'})}</MyTitle>
		{
			responses.map((response, i) => {
				const { hit, total, ldate } = response
				const value = hit / total * 100
				return (
					<LinearProgressWithLabel key={i} value={value > 100 ? 100 : value} used={hit} max={total} ldate={ldate || '1997-12-31'}/>
				)
			})
		}
		</>
	)
}

export default LinearWithValueLabelComponent