import { useEffect, useState, useCallback, useContext, memo } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW } from 'reducer/backdrop'
import { DialogActions, DialogTitle, DialogContent, Input } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { StyledDialog, StyledButton } from 'styled/Modal'
import { useIntl } from 'react-intl'
import { CREATE_SURVEY_WORKSPACE } from 'gql/survey_workspace'
import { getMaxlength } from 'policy'
import PropsContext from 'context/props'
import { GroupContext } from 'container/Survey/Workspace/Group'

const useStyles = makeStyles({
    content: {
        paddingTop: 10,
        paddingBottom: 10
    },
    button: {
        width: 60
    },
    listItemText: {
        alignItems: 'center'
    }
})

const Component = (props) => {
    const { refetchSurveyWorkspaces, onChangeSelected } = useContext(PropsContext)

    const { survey_workspace_group_no } = useContext(GroupContext)
    const { open, onClose } = props
    
    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    const [ title, setTitle ] = useState('')

    const [ createSurveyWorkspace ] = useMutation(CREATE_SURVEY_WORKSPACE, {
        onCompleted: async(res) => {
            setTimeout(() => {
                refetchSurveyWorkspaces()
            }, 100)

            setTimeout(() => {
                onChangeSelected(res.createSurveyWorkspace)
            }, 200)
        },
        onError: (e) => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const onChange = useCallback((e) => {
        const { value } = e.target
        setTitle(value)
    }, [])

    const onSave = useCallback(() => {
        dispatch({type: BACKDROPSHOW})

        createSurveyWorkspace({ 
            variables: { title, survey_workspace_group_no }
        })

        onClose()

    }, [dispatch, title, survey_workspace_group_no, onClose, createSurveyWorkspace])

    const disabled = title.length === 0

    // 창닫히면 title 값 빈값으로 변경
    useEffect(() => {
        if (!open) setTitle('')
    }, [open])

    return (
        <>
        <StyledDialog open={open} onClose={onClose} scroll={'paper'} fullWidth>
            <DialogTitle id="rows-dialog-title">{f({id: 'component.Survey.Workspace.Left.CreatePop.title'})}</DialogTitle>
            <DialogContent className={classes.content}>
                <Input
                    placeholder={f({id: 'component.Survey.Workspace.Left.CreatePop.input.placeholder'})}
                    inputProps={{
                        maxLength: getMaxlength('component.Survey.Create.Step.title')
                    }}
                    value={title}
                    fullWidth
                    variant="outlined"
                    onChange={onChange}

                />
            </DialogContent>
            
            <DialogActions>
                <StyledButton onClick={onSave} color="primary" disabled={disabled}>
                    {f({id: 'component.Survey.Workspace.Left.CreatePop.button.0'})}
                </StyledButton>
                <StyledButton onClick={onClose} color="default">
                    {f({id: 'component.Survey.Workspace.Left.CreatePop.button.1'})}
                </StyledButton>
            </DialogActions>
        </StyledDialog>
        </>
    )
}

export default memo(Component, (prev, next) => {
    return prev.open === next.open
})