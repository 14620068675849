import { useEffect, useState, useCallback, memo } from 'react'
import oc from 'open-color'
import { useIntl } from 'react-intl'
import { getMaxlength } from 'policy'
import { InputBase, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import OrderComponent from './Order'
import MoveWorkspaceButtonComponent from './MoveWorkspaceButton'
import DeleteWorkspaceButtonComponent from './DeleteWorkspaceButton'

const Input = withStyles({
    root: {
        border: `1px solid ${oc.gray[2]}`,
        transition: 'all .3s',
        borderRadius: 3,
        padding: '5px 10px',
        fontSize: 20,
        fontWeight: 700,
        '&:hover': {
            border: `1px solid ${oc.gray[3]}`
        },
        '&:focus': {
            border: `1px solid ${oc.gray[4]}`,
        }
    },
    focused: {
        border: `1px solid ${oc.gray[4]}`,
        background: 'white'
    }
})(InputBase)

let time = null

const Component = (props) => {
    const { 
        defaultTitle,
        base, 
        selected, 
        onChangeSurveyWorkspaceTitle, 
        onChangeOpenRemoveWorkspace,
        onChangeSelected,
        refetchSurveyWorkspaces
    } = props

    const [ title, setTitle ] = useState(defaultTitle)

    const { formatMessage: f } = useIntl()

    const changeTitle = useCallback(async(e) => {
        clearTimeout(time)
        const { value } = e.target

        setTitle(value)

        // 빈값은 저장안한다        
        // if (!value) return
        
        time = setTimeout(() => onChangeSurveyWorkspaceTitle({title: value, survey_workspace_no: selected}), 200)
    }, [onChangeSurveyWorkspaceTitle, selected])

    useEffect(() => {
        setTitle(defaultTitle)
    }, [defaultTitle])

    return (
        <Grid container alignItems="center" spacing={1} style={{marginTop: 8, marginBottom: 20}}>
            <Grid item xs={7} sm={9} md={10}>
                <Input 
                    value={title} 
                    placeholder={base ? f({id: 'component.Survey.Workspace.Left.Lists.defaultTitle'}) : ''}
                    onChange={changeTitle} 
                    fullWidth
                    inputProps={{
                        maxLength: getMaxlength('component.Survey.Create.Step.title')
                    }}
                    endAdornment={
                        <>
                        <MoveWorkspaceButtonComponent 
                            survey_workspace_no={selected}
                            onChangeSelected={onChangeSelected}
                            refetchSurveyWorkspaces={refetchSurveyWorkspaces}
                        />
                        <DeleteWorkspaceButtonComponent onClick={onChangeOpenRemoveWorkspace}/>
                        </>
                    }
                />
            </Grid>
            <Grid item xs={5} sm={3} md={2}>
                <OrderComponent/>
            </Grid>
        </Grid>
    )
}

export default memo(Component, (prev, next) => {
    // 타이틀 내용 바뀌면 surveyWorksrows가 리패치되는데... 여기는 바로 저장됨으로 rows 바꼇다고 재랜더링 될필요없다.
    // workspace 선탯번호가 바뀔때만 재랜더링되면된다
    return prev.selected === next.selected
})
