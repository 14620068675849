import { memo, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import { useIntl } from 'react-intl'
import { GET_SURVEY_WORKSPACE_GROUPS, SurveyWorkspaceGroupProps } from 'gql/survey_workspace_group'
import { SHOW as ERRORORPAGESHOW } from 'reducer/errorPage'
import { 
    MenuItem,
    Typography,
    FormControl,
    Select,
    InputLabel,
    CircularProgress
} from '@material-ui/core'
import { GroupContext, GroupContextProps } from 'container/Survey/Workspace/Group'
import { Selected, OnChange } from './MoveWorkspaceButton'
import { getDefaultWorkspaceGroupName } from '../Left/Group/Items'

interface Props {
    selected: Selected;
    onChange: OnChange;
}

function MoveWorkspaceSelectComponent(props: Props) {
    const { selected, onChange } = props

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const { survey_workspace_group_no: selectedNo } = useContext<GroupContextProps>(GroupContext)

    const [ getRows, { data } ] = useLazyQuery<{surveyWorkspaceGroups: SurveyWorkspaceGroupProps[]}>(GET_SURVEY_WORKSPACE_GROUPS, {
        fetchPolicy: 'network-only',
        onError: () => {
            dispatch({ type: ERRORORPAGESHOW })
        }
    })

    useEffect(() => {
        getRows()
    }, [getRows])


    if (!data) return <CircularProgress size={15}/>

    const rows = data.surveyWorkspaceGroups.filter(c => c.survey_workspace_group_no !== selectedNo)

    if (rows.length === 0) return <Typography>{f({id: 'component.Survey.Workspace.Body.MoveWorkspaceButtonSelect.null'})}</Typography>

    return (
        <FormControl fullWidth>
            <InputLabel htmlFor="move-workspace">
                {f({id: 'component.Survey.Workspace.Body.MoveWorkspaceButtonSelect.label'})}
            </InputLabel>
            <Select value={selected.survey_workspace_group_no === 0 ? '' : selected.survey_workspace_group_no} onChange={(e) => {
                const value = Number(e.target.value)
                const is = rows.find(c => c.survey_workspace_group_no === value)
                const { title } = is || { title: '' }
                onChange({ survey_workspace_group_no: value, title }) 
            }}>
                {
                    rows.map(c => {
                        const { survey_workspace_group_no, title } = c
                        return (
                            <MenuItem key={survey_workspace_group_no} value={survey_workspace_group_no}>
                                <Typography variant="body2">
                                    {getDefaultWorkspaceGroupName(f, title)}
                                </Typography>
                            </MenuItem>
                        )
                    })
                }
            </Select>
        </FormControl>
    )
}


export default memo(MoveWorkspaceSelectComponent)