// import Dialog from './Dialog'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { HelpOutline as HelpOutlineIcon } from '@material-ui/icons'
import { useIntl } from 'react-intl'

export const openHelpPage = () => {
    const link = `${process.env.REACT_APP_LANDING}/support/help`

    window.open(link, "_blank")
}


export default function ItemFaqComponent() {
    const { formatMessage: f } = useIntl()

    return (
        <>
        <ListItem button onClick={openHelpPage}>
            <ListItemIcon>
                <HelpOutlineIcon fontSize="small"/>
            </ListItemIcon>
            <ListItemText primary={f({id: 'component.Survey.Workspace.Left.Help.ItemHelp.title'})} />
        </ListItem>
        {
            /**
             * 미사용
             * <Dialog open={open} title={f({id: 'component.Survey.Workspace.Left.Help.ItemFaq.title'})} src={`${process.env.REACT_APP_LANDING}/pure/faq`} onClose={handleClose}/>
             */
        }
        </>
    )
}

 