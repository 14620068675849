import { memo, useState, useCallback } from 'react'
import Menu from '@material-ui/core/Popover'
import IconButton from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { ItemsProp } from './Component'
import AddComponent from './Add'
import UpdateNameComponent from './UpdateName'
import DeleteGroupComponent from './DeleteGroup'
import { GroupContextProps } from 'container/Survey/Workspace/Group'

export interface Props {
    itemLength: number;
    survey_workspace_group_no: ItemsProp['survey_workspace_group_no'];
    title: ItemsProp['title'];
    refetch: () => void;
    onChange: GroupContextProps['onChange'];
}

export type OnClose = () => void;

function MoreComponent(props: Props) {
    const { itemLength, survey_workspace_group_no, title, refetch, onChange } = props

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    }
  
    const handleClose: OnClose = useCallback(() => {
        setAnchorEl(null)
    }, [])

    return (
        <>
        <IconButton size='small' onClick={handleClick}>
            <MoreHorizIcon/>
        </IconButton>
        <Menu
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <div>
                <AddComponent 
                    refetch={refetch} 
                    onClose={handleClose}
                    onChange={onChange}
                />
                <UpdateNameComponent 
                    survey_workspace_group_no={survey_workspace_group_no} 
                    title={title} 
                    refetch={refetch} 
                    onClose={handleClose}
                />
                <DeleteGroupComponent 
                    itemLength={itemLength} 
                    title={title} 
                    survey_workspace_group_no={survey_workspace_group_no} 
                    refetch={refetch}
                    onClose={handleClose}
                    onChange={onChange}
                />
            </div>
        </Menu>
        </>
        
    )
}

export default memo(MoreComponent)