import { useState, useContext, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    Typography
} from '@material-ui/core'
import { withBreakPoints } from 'hoc'
import { HIDE as POPOVERHIDE } from 'reducer/popover'
import { GroupContext } from 'container/Survey/Workspace/Group'
import WorkspaceGroupByDataComponent, { Type } from './WorkspaceGroupByData'

const Component = props => {
    const { selected: survey_workspace_no, open, survey_no, onCopy, onClose, breakpoints } = props
    const { xs } = breakpoints

    const { survey_workspace_group_no } = useContext(GroupContext)

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const [ targetSurveyWorkspaceGroupNo, setTargetSurveyWorkspaceGroupNo ] = useState(survey_workspace_group_no)

    const [ targetSurveyWorkspaceNo, setTargetSurveyWorkspaceNo ] = useState(survey_workspace_no)


    const handleChangeWorkspaceGroup = useCallback((value) => {
        setTargetSurveyWorkspaceGroupNo(value)
    }, [])

    const handleChangeWorkspace = useCallback((value) => {
        setTargetSurveyWorkspaceNo(value)
    }, [])

    const handleCopy = () => {
        dispatch({type: POPOVERHIDE})
        setTimeout(() => {
            onCopy({survey_no, target_survey_workspace_no: targetSurveyWorkspaceNo})
        }, 50)
    }

    // 열때마다 디폴트값을 선택해준다.
    useEffect(() => {
        if (open) {
            setTargetSurveyWorkspaceGroupNo(survey_workspace_group_no)
            setTargetSurveyWorkspaceNo(survey_workspace_no)
        }
    }, [open, survey_workspace_group_no, survey_workspace_no])

    return (
        <Dialog open={open} onClose={onClose} fullWidth fullScreen={xs}>
            <DialogTitle id="form-dialog-title">{f({id: 'component.Survey.Workspace.Body.CopySurvey.title'})}</DialogTitle>
            <DialogContent>
                <WorkspaceGroupByDataComponent 
                    type={Type.Create} 
                    open={open} 
                    targetSurveyWorkspaceGroupNo={targetSurveyWorkspaceGroupNo}
                    targetSurveyWorkspaceNo={targetSurveyWorkspaceNo}
                    survey_workspace_no=""
                    onChangeWorkspaceGroup={handleChangeWorkspaceGroup}
                    onChangeWorkspace={handleChangeWorkspace}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCopy} color="primary" disabled={!targetSurveyWorkspaceGroupNo || !targetSurveyWorkspaceNo}>
                    <Typography variant="button">{f({id: 'component.Survey.Workspace.Body.CopySurvey.button.0'})}</Typography>
                </Button>
                <Button onClick={onClose} color="default">
                    <Typography variant="button">{f({id: 'component.Survey.Workspace.Body.CopySurvey.button.1'})}</Typography>
                </Button>
            </DialogActions>
        </Dialog> 
    )
}

export default withBreakPoints(Component)