import { useCallback } from 'react'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { ChatOutlined as ChatOutlinedIcon } from '@material-ui/icons'
import { useIntl } from 'react-intl'
import useTokenInfo from 'hooks/useTokenInfo'
import useChannelIO from 'hooks/useChannel'

export default function ItemFaqComponent() {
    const { formatMessage: f } = useIntl()

    const token = useTokenInfo()

    const channelIO = useChannelIO({ user_no: token.user_no, email: token.email })

    const handleChannelOpen = useCallback(() => {
        async function _do() {
            const ChannelIO = await channelIO()
            ChannelIO('showMessenger')
        }
        _do()
    }, [channelIO])


    return (
        
        <ListItem button onClick={handleChannelOpen}>
            <ListItemIcon>
                <ChatOutlinedIcon fontSize="small"/>
            </ListItemIcon>
            <ListItemText primary={f({id: 'component.Survey.Workspace.Left.Help.ItemChat.title'})} />
        </ListItem>
        
    )
}

 