import { useState, useCallback, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { PaymentOutlined as PaymentOutlinedIcon } from '@material-ui/icons'
import withPrevState from 'hoc/PrevState'
import { PPP } from 'container/Survey/Workspace/Container'
import Dialog from './Dialog'

function ItemPriceComponent(props) {
    const { usePrevState } = props

    const { formatMessage: f } = useIntl()

    const { flag } = useContext(PPP)

    const prevFlag = usePrevState(flag)

    const [ open, setOpen ] = useState(false)

    const handleOpen = useCallback(() => {
        setOpen(true)
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    useEffect(() => {

        if (flag > 0 && prevFlag !== undefined) {
            if (flag !== prevFlag) {
                setOpen(true)
            }
        }
    }, [flag, prevFlag])


    return (
        <>
        <ListItem button onClick={handleOpen}>
            <ListItemIcon>
                <PaymentOutlinedIcon fontSize="small"/>
            </ListItemIcon>
            <ListItemText primary={f({id: 'component.Survey.Workspace.Left.Help.ItemPrice.title'})} />
        </ListItem>
        <Dialog open={open} title={f({id: 'component.Survey.Workspace.Left.Help.ItemPrice.subtitle'})} src={`${process.env.REACT_APP_LANDING}/pure/price`} onClose={handleClose}/>
        </>
    )
}

export default withPrevState(ItemPriceComponent)

 