import { memo } from 'react'
import { useIntl } from 'react-intl'
import { SurveyWorkspaceProps } from 'gql/survey_workspace'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { getTitle } from 'component/Survey/Workspace/Left/Lists'
import { OnChangeWorkspace, OnChangeSurveyWdate , OnInitSurveyWdate, OnChangeSurveyTitle } from './Items'
import SearchDateComponent from './SearchDate'
import SearchTitleComponent from './SearchTitle'

export interface Props {
    workspaceRows: SurveyWorkspaceProps[];
    workspaceNo: SurveyWorkspaceProps['survey_workspace_no'];
    date: string;
    title: string;
    onChangeWorkspace: OnChangeWorkspace;
    onChangeSurveyWdate: OnChangeSurveyWdate;
    onInitSurveyWdate: OnInitSurveyWdate;
    onChangeSurveyTitle: OnChangeSurveyTitle;
}

const MySelect = withStyles(theme => ({
    outlined: {
        width: '100%',
        background: 'white',
        maxWidth: 190,
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%'            
        }
  
    }
}))(Select)

function SearchComponent(props: Props) {
    const { workspaceRows, workspaceNo, date, title, onChangeWorkspace, onChangeSurveyWdate, onInitSurveyWdate, onChangeSurveyTitle } = props

    const { formatMessage: f } = useIntl()

    return (
        <>
        <Typography variant="h6" gutterBottom>{f({id: 'component.Survey.Workspace.Left.Utility.Download.Search.title'})}</Typography>
        <Grid container spacing={1}>
            <Grid item xs={12} sm="auto" md="auto">
                <MySelect value={workspaceNo} variant="outlined" style={{width: '100%'}} onChange={onChangeWorkspace}>
                    {
                        workspaceRows.map(row => (
                            <MenuItem key={row.survey_workspace_no} value={row.survey_workspace_no}>{getTitle(f, row)}</MenuItem>
                        ))
                    }
                </MySelect>
            </Grid>
            <Grid item xs={12} sm="auto" md="auto">
                <SearchDateComponent date={date} onChange={onChangeSurveyWdate} onInit={onInitSurveyWdate}/> 
            </Grid>
            <Grid item xs={12} sm="auto" md="auto">
                <SearchTitleComponent title={title} onChange={onChangeSurveyTitle}/>
            </Grid>
        </Grid>
        </>
    )
}

export default memo(SearchComponent)