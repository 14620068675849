import { memo } from 'react'
import { useHistory } from "react-router-dom"
import { useDispatch } from 'react-redux'
import { useIntl } from "react-intl"
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListIcon from '@material-ui/icons/List'

interface Props {
    survey_workspace_no: number;
}

function DownloadComponent(props: Props) {
    const { survey_workspace_no } = props

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const history = useHistory()

    function handleMove() {
        if (!survey_workspace_no) {
            dispatch({ 
                type: ALERTSNACKBARSHOW,
                message: f({id: 'component.Survey.Workspace.Left.Utility.Analysis.Component.error'}),
                duration: 4000
            })
            return
        }

        history.push(`/analysis/${survey_workspace_no}/0`)
    }

    return (
        <ListItem button onClick={handleMove}>
            <ListItemIcon>
                <ListIcon fontSize="small" color="primary"/>
            </ListItemIcon>
            <ListItemText primary={f({id: 'component.Survey.Workspace.Left.Utility.Analysis.Component.title'})}/>
        </ListItem>
    )
}

export default memo(DownloadComponent)