import { memo, useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { parseHtmlIntl } from 'ts-utils'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Dialog from "styled/Survey/Live/Dialog"
import { OnOpen, OnCreate } from './Add'
import TemplateComponent from './Template/Component'

interface Props {
    open: boolean
    onClose: OnOpen
    onCreate: OnCreate
}

export type OnTemplateClose = () => void

const useStyles = makeStyles({
    root: {
      width: '100%',
      padding: 10,
      height: 340
    },
    media: {
        width: '100%',
        height: 150
    },
})

const MyActions = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    }
}))(DialogActions)

function SelectCreateBoxComponent(props: Props) {
    const { open, onClose, onCreate } = props

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const [ templateOpen, setTempateOpen ] = useState(false)
    
    const handleTemplateOpen = useCallback(() => {
        onClose()
        setTempateOpen(true)
    }, [onClose])

    const handleTemplateClose = useCallback(() => {
        setTempateOpen(false)
    }, [])

    return (
        <>
        <Dialog open={open} fullScreen={false} fullWidth={true} onClose={onClose}>
            <DialogContent style={{paddingBottom: 20}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Card className={classes.root} onClick={handleTemplateOpen}>
                            <CardActionArea>
                                <CardMedia
                                    component="div"
                                    className={classes.media}
                                    image={`${process.env.REACT_APP_CF}/static/template-preview.png`}
                                    title="template-preview"
                                />
                                <CardContent>
                                <Typography gutterBottom variant="h6" component="h2">
                                    {f({id: 'component.Survey.Workspace.Body.SelectCreateBox.title.0'})}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {parseHtmlIntl(f({id: 'component.Survey.Workspace.Body.SelectCreateBox.explain.0'}))}
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card className={classes.root} onClick={onCreate}>
                            <CardActionArea>
                                <CardMedia
                                    component="div"
                                    className={classes.media}
                                    image={`${process.env.REACT_APP_CF}/static/template-preview-white.jpg`}
                                    title="template-preview"
                                />
                                <CardContent>
                                <Typography gutterBottom variant="h6" component="h2">
                                    {f({id: 'component.Survey.Workspace.Body.SelectCreateBox.title.1'})}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {parseHtmlIntl(f({id: 'component.Survey.Workspace.Body.SelectCreateBox.explain.1'}))}
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
         
            </DialogContent>
            <MyActions>
                <Button onClick={onClose}>닫기</Button>
            </MyActions>
        </Dialog>
        <TemplateComponent open={templateOpen} onClose={handleTemplateClose}/>
        </>
    )
}

export default memo(SelectCreateBoxComponent)