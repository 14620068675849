import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import { useIntl } from 'react-intl'

export default function ItemFaqComponent() {
    const { formatMessage: f } = useIntl()

    return (
        <>
        <ListItem 
            button 
            onClick={() => window.open('https://listovey-update.notion.site/listovey-update/1daebc93d1ad48a3a52ae132d42ed2bc?v=80918422d7f64047be5ee2a3482796bd', '_blank')}
        >
            <ListItemIcon>
                <NewReleasesIcon fontSize="small"/>
            </ListItemIcon>
            <ListItemText primary={f({id: 'component.Survey.Workspace.Left.Help.ItemUpdate.title'})} />
        </ListItem>
        </>
    )
}

 