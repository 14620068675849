import { memo, useCallback, useState, ChangeEvent } from 'react'
import { useIntl } from 'react-intl'
import { Step } from 'gql/survey_analysis_summary'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import ConditionSelectComponent from './ConditionSelect'
import ConditionDateComponent from './ConditionDate'
import { ConditionContextProps, AnswerType, FileType, OnCloseCollapse } from './Items'
import ConditionPersonalComponent from './ConditionPersonal'
import ConditionRowsComponent from './ConditionRows'
import ConditionConfirmComponent from './ConditionConfirm'

interface Props extends ConditionContextProps {
    onCloseCollapse: OnCloseCollapse;
}
export type OnChangeStep = (e: ChangeEvent<{ value: Step }>) => void
export type OnChangeDate = (val: string) => void
export type OnInitDate = () => void
export type OnChangePersonalIsAnswerd = (e: ChangeEvent<HTMLInputElement>) => void
export type OnChangeRowsAnswerType = (e: ChangeEvent<{ value: AnswerType }>) => void
export type OnChangeRowsFileType = (e: ChangeEvent<{ value: FileType }>) => void
export type OnRollback = () => void

const MyBox = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        '& > div': {
            height: 56
        },
        '& > div + div': {
            marginLeft: 5
        }
    }
})(Box)



function ConditionComponent(props: Props) {
    const { step, date, rows, personal, onChange, onCloseCollapse } = props

    const { formatMessage: f } = useIntl()

    const [ condition, setCondition ] = useState({ step, date, rows, personal})
    
    // step 변경
    const handleChangeStep: OnChangeStep = useCallback(e => {
        const { value } = e.target
        setCondition(prevState => ({
            ...prevState,
            step: value
        }))
    }, [])

    // date 변경
    const handleChangeDate: OnChangeDate = useCallback(val => {
        setCondition(prevState => ({
            ...prevState,
            date: val
        }))
    }, [])

    // date 초기화
    const handleInitDate: OnInitDate = useCallback(() => {
        setCondition(prevState => ({
            ...prevState,
            date: ''
        }))
    }, [])

    // 개인별 응답 상태값 변경
    const handleChangePersonalIsAnswerd: OnChangePersonalIsAnswerd = useCallback((e) => {
        const { checked } = e.target
        setCondition(prevState => ({
            ...prevState,
            personal: {
                isAnswerd: checked
            }
        }))
    }, [])

    // 응답데이터 내용 포맷 상태값 변경
    const handleChangeRowsAnswerType: OnChangeRowsAnswerType = useCallback((e) => {
        const { value } = e.target
        setCondition(prevState => ({
            ...prevState,
            rows: {
                ...prevState.rows,
                answerType: value
            }
        }))
    }, [])

    // 응답데이터 내용 포맷 상태값 변경
    const handleChangeRowsFileType: OnChangeRowsFileType = useCallback((e) => {
        const { value } = e.target
        setCondition(prevState => ({
            ...prevState,
            rows: {
                ...prevState.rows,
                fileType: value
            }
        }))
    }, [])

    // 이전으로 
    const handleRollback: OnRollback = useCallback(() => {
        setCondition({ step, date, rows, personal })
    }, [step, date, rows, personal])

    return (
        <>
        <Typography variant="h6" gutterBottom>{f({id: 'component.Survey.Workspace.Left.Utility.Download.Condition.ctitle'})}</Typography>
        <MyBox>
            <div>
                <ConditionSelectComponent step={condition.step} onChange={handleChangeStep}/>
            </div>
            <div>
                <ConditionDateComponent date={condition.date} onChange={handleChangeDate} onInit={handleInitDate}/>
            </div>
        </MyBox>
        <Typography variant="h6" style={{marginTop: 20}} gutterBottom>{f({id: 'component.Survey.Workspace.Left.Utility.Download.Condition.rtitle'})}</Typography>
        <ConditionPersonalComponent personal={condition.personal} onChange={handleChangePersonalIsAnswerd}/>
        <ConditionRowsComponent rows={condition.rows} onChangeAnswerType={handleChangeRowsAnswerType} onChangeFileType={handleChangeRowsFileType}/>
        <ConditionConfirmComponent
            origin={{step, date, rows, personal }}
            condition={condition}
            onChange={onChange}
            onRollback={handleRollback}
            onCloseCollapse={onCloseCollapse}
        />
        </>
    )
}

export default memo(ConditionComponent)