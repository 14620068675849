import { memo, ChangeEvent, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { getMaxlength } from 'policy'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Input from '@material-ui/core/Input'
import { StyledDialog, StyledButton } from 'styled/Modal'

export enum Type {
    Create,
    Update
}

interface Props {
    type: Type;
    open: Boolean;
    title: string;
    onClose: () => void;
    onSave: (title: string) => void;
}

function TitleDialogComponent(props: Props) {
    const { type, open, title, onClose, onSave } = props

    const { formatMessage: f } = useIntl()

    const [ newTitle, setNewTitle ] = useState('')

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setNewTitle(e.target.value)
    }

    useEffect(() => {
        if (open) setNewTitle(title)
    }, [open, title]) 

    const intl = Type.Create === type ? 'create' : 'edit'

    return (
        <StyledDialog open={open} onClose={onClose} scroll={'paper'} fullWidth>
                <DialogTitle>
                    {f({id: `component.Survey.Workspace.Left.Group.TitleDialog.${intl}.title`})}
                </DialogTitle>            
            <DialogContent>
                <Input
                    placeholder={f({id: 'component.Survey.Workspace.Left.Group.TitleDialog.placeholder'}, {maxLength: getMaxlength('component.Survey.Workspace.Left.Group.Add.title')})}
                    inputProps={{
                        maxLength: getMaxlength('component.Survey.Workspace.Left.Group.Add.title')
                    }}
                    value={newTitle}
                    fullWidth
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <StyledButton onClick={() => onSave(newTitle)} color="primary" disabled={newTitle.trim() === ''}>
                    {f({id: `component.Survey.Workspace.Left.Group.TitleDialog.button.${intl}`})}
                </StyledButton>
                <StyledButton onClick={onClose} color="default">
                    {f({id: 'component.Survey.Workspace.Left.Group.TitleDialog.button.close'})}
                </StyledButton>
            </DialogActions>
        </StyledDialog>
    )
}

export default memo(TitleDialogComponent)