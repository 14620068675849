import { memo, useState, ChangeEvent } from 'react'
import { useIntl } from 'react-intl'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import { OnChangeSurveyTitle } from './Items'

export interface Props {
    title: string;
    onChange: OnChangeSurveyTitle;
}

let timer: ReturnType<typeof setTimeout> 

function SearchComponent(props: Props) {
    const { title, onChange } = props

    const { formatMessage: f } = useIntl()

    const [ newTitle, setNewTitle ] = useState(title)

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        const { value } = e.target
        setNewTitle(value)
        clearTimeout(timer)

        timer = setTimeout(() => {
            onChange(value)
        }, 350)
    }

    return (
        <OutlinedInput 
            fullWidth
            style={{background: 'white'}}
            placeholder={f({id: 'component.Survey.Workspace.Left.Utility.Download.SearchTitle.placeholder'})}
            value={newTitle}
            onChange={handleChange}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton edge="end">
                        <SearchIcon />
                    </IconButton>
                </InputAdornment>
            }
        />
    )
}

export default memo(SearchComponent)