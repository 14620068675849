import { memo, useState, useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { CREATE_SURVEY_WORKSPACE_GROUP } from 'gql/survey_workspace_group'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import AddIcon from '@material-ui/icons/Add'
import TitleDialogComponent, { Type } from './TitleDialog'
import { OnClose } from './More'
import { Props as ParentProps } from './Component'

interface Props {
    onChange: ParentProps['onChange'];
    refetch: ParentProps['refetch'];
    onClose: OnClose;
}

function AddComponent(props: Props) {
    const { refetch, onClose, onChange } = props

    const [ open, setOpen ] = useState(false)

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const [ create ] = useMutation(CREATE_SURVEY_WORKSPACE_GROUP, {
        onCompleted: (data) => {
            setTimeout(() => {
                refetch()
            }, 100)
            setTimeout(() => {
                onChange(data.createSurveyWorkspaceGroup)
                setOpen(false)
            }, 200)
        },
        onError: () => {
            setOpen(false)
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const handleOpen = () => {
        setOpen(true)
        onClose()
    }

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const handleSave = useCallback((title: string) => {
        create({
            variables: { title }
        })
    }, [create])

    return (
        <>
        <MenuItem dense onClick={handleOpen} style={{paddingTop: 10, paddingBottom: 10}}>
            <ListItemIcon>
                <AddIcon fontSize="small"/>
            </ListItemIcon>
            <Typography variant="inherit">
                {f({id: 'component.Survey.Workspace.Left.Group.Add.title'})}
            </Typography>
        </MenuItem>     
        <Divider light/>
        <TitleDialogComponent type={Type.Create} open={open} title='' onClose={handleClose} onSave={handleSave}/>
        </>
    )
}

export default memo(AddComponent)