import { memo } from 'react'
import { useIntl } from 'react-intl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import { ConditionContextProps } from './Items'
import { OnChangePersonalIsAnswerd } from './Condition'

interface Props {
    personal: ConditionContextProps['personal'];
    onChange: OnChangePersonalIsAnswerd;
}
function SearchComponent(props: Props) {
    const { personal, onChange } = props
    const { isAnswerd } = personal

    const { formatMessage: f } = useIntl()

    return (
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
                <Typography>{f({id: 'component.Survey.Workspace.Left.Utility.Download.ConditionPersonal.title'})}</Typography>
            </Grid>
            <Grid item>
                <FormControlLabel
                    value="start"
                    control={<Switch color="primary" checked={isAnswerd} onChange={onChange}/>}
                    label={f({id: 'component.Survey.Workspace.Left.Utility.Download.ConditionPersonal.isAnswerd.title'})}
                    labelPlacement="start"
                />
            </Grid>
        </Grid>
    )
}

export default memo(SearchComponent)