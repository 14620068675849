import { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/styles'
import useTokenInfo from 'hooks/useTokenInfo'
import useUpdateToken from 'hooks/useUpdateToken'
import PlanComponent from "./Plan"
import ResponseComponent from "./Response"


const MyBox = withStyles({
	root: {
		padding: '18px 18px 20px 18px',
		maxHeight: 250,
		overflowY: 'auto'
	}
})(Box)

let time: ReturnType<typeof setTimeout>;


function Component() {
	const updateToken = useUpdateToken()
	const token = useTokenInfo()

	const { levels, ldate, reservation_levels, reservation_levels_date, responses } = token


	useEffect(() => {
		clearTimeout(time)
		time = setTimeout(() => {
			updateToken()
		}, 1000)

		return () => {
			clearTimeout(time)
		}
	}, [updateToken])


	return (
		<>
		<Divider/>
		<MyBox>
			<PlanComponent 
				levels={levels} 
				ldate={ldate} 
				reservation_levels={reservation_levels}
				reservation_levels_date={reservation_levels_date}
			/>
			<div style={{height: 20}}/>
			<ResponseComponent responses={responses}/>
		</MyBox>
		</>
	)
}

export default Component