import ReactHtmlParser from 'react-html-parser'
import { useIntl } from 'react-intl'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { withBreakPoints } from 'hoc'

const Component = props => {
    const { open, onRemove, onClose, breakpoints } = props
    const { xs } = breakpoints

    const { formatMessage: f } = useIntl()

    const content = f({id: 'component.Survey.Workspace.Body.RemoveWorkspace.content'}).replace(/\n/g, `<br />`)

    return (
        <Dialog open={open} onClose={onClose} fullWidth fullScreen={xs}>
            <DialogTitle>{f({id: 'component.Survey.Workspace.Body.RemoveWorkspace.title'})}</DialogTitle>
            <DialogContent>
                <Typography>{ReactHtmlParser(content)}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onRemove} color="secondary">
                    <Typography variant="button">{f({id: 'component.Survey.Workspace.Body.RemoveWorkspace.button.0'})}</Typography>
                </Button>
                <Button onClick={onClose}>
                    <Typography variant="button">{f({id: 'component.Survey.Workspace.Body.RemoveWorkspace.button.1'})}</Typography>
              </Button>
            </DialogActions>
        </Dialog> 
    )
}

export default withBreakPoints(Component)