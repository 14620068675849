import { useIntl } from 'react-intl'
import oc from 'open-color'
import moment from 'moment'
import { GetLevels, Levels } from 'ts-policy'
import Box from '@material-ui/core/Box'
import Typography from "@material-ui/core/Typography"
import { withStyles } from '@material-ui/core/styles'
import { TokenTypes } from 'hooks/useTokenInfo'
import { MyTitle } from './Response'

interface Props {
    levels: TokenTypes['levels'];
    ldate: TokenTypes['ldate'];
    reservation_levels: TokenTypes['reservation_levels'];
    reservation_levels_date: TokenTypes['reservation_levels_date'];
}

const MyBox = withStyles({
    root: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        fontSize: `12px !important`,
        '& + &': {
            marginTop: 2
        }
    }
})(Box)

const MyName = withStyles({
    root: {
        textShadow: `1px 1px ${oc.indigo[1]}`,
        fontSize: 13
    }
})(props => <Typography {...props} variant="body2"/>)

function PlanComponent(props: Props) {
    const { levels, ldate, reservation_levels, reservation_levels_date } = props

    const { formatMessage: f } = useIntl()

    const { name } = GetLevels(levels)

    const { name: reservationName } = (reservation_levels && reservation_levels_date) ? GetLevels(reservation_levels) : { name: '' }

    let Ldate = moment(ldate).format('YY.MM.DD')

    // reservation 이 있을경우
    if (reservation_levels && reservation_levels_date) {
        Ldate = moment(reservation_levels_date).subtract(1, "days").format('YY.MM.DD')
    }

    return (
        <>
        <MyTitle>{f({id: 'component.Survey.Workspace.Left.Info.Plan.title'})}</MyTitle>
        <MyBox>
            <MyName>{name}</MyName>
            <MyName>
                {(ldate && levels !== Levels.Level0) ? `~${Ldate}` : ''}
            </MyName>
        </MyBox>
        {
            (reservation_levels && reservation_levels_date) && (
                <MyBox>
                    <MyName>{reservationName}</MyName>
                    <MyName>
                        {`~${moment(ldate).format('YY.MM.DD')}`}
                    </MyName>
                </MyBox>
            )
        }
        </>
    )
}

export default PlanComponent