import { useState, useEffect, useTransition } from 'react'
import { useIntl } from 'react-intl'
import { getDownloadTitle } from 'utils/analysis'
import { SurveyAnalysisRowsProps } from 'gql/survey_analysis_rows'
import { CSVLink } from 'react-csv'
import CircularProgress from '@material-ui/core/CircularProgress'
import { DownloadFormat } from 'component/Survey/Create/NewAnalysis/Rows/Raw'
import { AnswerType, FileType } from './Items'
import { MyButton } from './DownloadPersonal'

interface Props {
    data: SurveyAnalysisRowsProps;
    fileType: FileType;
    answerType: AnswerType;
    title: string;
}

const downloadFormat = new DownloadFormat()

function DownloadRowsComponent(props: Props) {
    const { data, fileType, answerType, title: ctitle } = props
    const { columns, answers } = data

    const title = getDownloadTitle({title: ctitle, type: 'Rows' })

    downloadFormat.reference = { columns, answers, order: '', orderBy: '', answerType, isSort: false }

    const { formatMessage: f } = useIntl()

    const [ csv, setCsv ] = useState<any[]>([])

    // 엑셀 다운로드 시 로딩이미지
    const [ loadingExcel, setExcelLoading ] = useState(false)

    const [ isPendingCsv, startTransitionCsv ] = useTransition()

    const handleSaveExcel = async() => {
        setExcelLoading(true)
        setTimeout(async () => {
            await downloadFormat.makeEXCEL(title)
            setExcelLoading(false)
        }, 250)
    }

    useEffect(() => {
        const Do = async() => {
            if (fileType === 'csv') {
                startTransitionCsv(() => {
                    setCsv(downloadFormat.makeCSV())
                })
            }
        }

        Do()
      
    }, [columns, answers, answerType, fileType, startTransitionCsv ])

    return (
        <>
        <CSVLink 
            data={csv}
            filename={`${title}.csv`} 
            style={{display: fileType === FileType.Csv ? 'block' : 'none', textDecoration: 'none'}}
            asyncOnClick={true}
            onClick={(e, done) => {
                done(!isPendingCsv)
            }}
        >
            <MyButton 
                color="primary" 
                startIcon={isPendingCsv ? <CircularProgress size={10}/> : null} 
                disabled={isPendingCsv}
            >
                {f({id: 'component.Survey.Workspace.Left.Utility.Download.DownloadRows.button'})}
            </MyButton>
        </CSVLink>
        {
            loadingExcel ? (
                <MyButton 
                    color="primary" 
                    startIcon={<CircularProgress size={10}/>}
                    style={{display: fileType === FileType.Xlsx ? 'flex' : 'none'}}
                    disabled={true}
                >
                    {f({id: 'component.Survey.Workspace.Left.Utility.Download.DownloadRows.button'})}
                </MyButton>
            ) : (
                <MyButton 
                    color="primary" 
                    style={{display: fileType === FileType.Xlsx ? 'flex' : 'none', marginRight: 2}}
                    onClick={handleSaveExcel}
                >
                    {f({id: 'component.Survey.Workspace.Left.Utility.Download.DownloadRows.button'})}
                </MyButton>
            )
        }
        </>
    )    
}

export default DownloadRowsComponent