import { memo } from 'react'
import { useIntl } from 'react-intl'
import { parseHtmlIntl } from 'ts-utils'
import Dialog from 'styled/Survey/Live/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

interface Props {
    open: boolean;
    limit: number;
    onClose: () => void;
}

function LimitDiaLogComponent(props: Props) {
    const { open, limit, onClose } = props

    const { formatMessage: f } = useIntl()

    return (
        <Dialog fullScreen={false} maxWidth="sm" fullWidth={true} open={open} onClose={onClose}>
            <DialogTitle>{f({id: 'component.Survey.Workspace.Body.LimitDialog.title'})}</DialogTitle>
            <DialogContent>
                {parseHtmlIntl(f({id: 'component.Survey.Workspace.Body.LimitDialog.content'}, { count: limit }))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{f({id: 'component.Survey.Workspace.Body.LimitDialog.button.close'})}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default memo(LimitDiaLogComponent)