import { useIntl } from 'react-intl'
import { isEqual } from 'lodash'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { ConditionContextProps, OnCloseCollapse } from './Items'
import { OnRollback } from './Condition'

interface Props {
    origin: Omit<ConditionContextProps, 'onChange'>;
    condition: Omit<ConditionContextProps, 'onChange'>;
    onChange: ConditionContextProps['onChange'];
    onRollback: OnRollback;
    onCloseCollapse: OnCloseCollapse;
}

const MyBox = withStyles({
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 5,
        marginTop: 20
    }
})(Box)

const CollapseButton = withStyles(theme => ({
    root: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'flex'
        }
    }
}))(Button)

function ConditionConfirmComponent(props: Props) {
    const { origin, condition, onChange, onRollback, onCloseCollapse } = props

    const equal = isEqual(origin, condition)

    const { formatMessage: f } = useIntl()

    function handleChange() {
        onChange(condition)
    }

    return (
        <MyBox>
            <Button size="large" color="primary" variant="contained" fullWidth disabled={equal} onClick={handleChange}>
                {f({id: 'component.Survey.Workspace.Left.Utility.Download.ConditionConfirm.button.confirm'})}
            </Button>
            <Button size="large" variant="contained" fullWidth disabled={equal} onClick={onRollback}>
                {f({id: 'component.Survey.Workspace.Left.Utility.Download.ConditionConfirm.button.prev'})}
            </Button>
            <CollapseButton size="large" variant="contained" fullWidth onClick={onCloseCollapse}>
                {f({id: 'component.Survey.Workspace.Left.Utility.Download.SearchGrid.button.0'})}
            </CollapseButton>
        </MyBox>
    )
}

export default ConditionConfirmComponent