import { useCallback, useState, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import oc from 'open-color'
import { IconButton, Box, Divider, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon, Search as SearchIcon, CreateNewFolder as CreateNewFolderIcon } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { withBreakPoints } from 'hoc'
import Tooltip from 'styled/Custom/Tooltip'
import { SurveyWorkspaceLeftCreatePopComponent, SurveyWorkspaceLeftSearchPopComponent } from 'component'
import GroupComponent from './Group/Component'

const useStyles = makeStyles(theme => ({
    expand: {
        color: theme.palette.action.active,
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            display: 'flex'
 
        },
        display: 'none',
    },
    expandSpan: {
        display: 'inline'
    },
    title: {
        color: theme.palette.common.black
    }
}))

const ContainerBox = withStyles(theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            '& > div': {
                padding: 5
            }
        }
    }
}))(Box)

const WrapperBox = withStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 5,
        paddingRight: 5,
        
        
    }
}))(Box)

const Component = (props) => {
    const { openMobileLeft, onChangeOpenMobileLeft } = useContext(PropsContext)
    const { breakpoints } = props
    const { sm } = breakpoints // sm 아래일 때만 onChangeOpenMobileLeft 작동
    
    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const [ openAdd, setOpenAdd ] = useState(false)
    const [ openSearch, setOpenSearch ] = useState(false)

    const changeOpenAdd = useCallback((_open) => setOpenAdd(_open), [])
    const changeOpenSearch = useCallback((_open) => setOpenSearch(_open), [])

    return (
        <>
        <div style={{paddingLeft: 5}}>
            <GroupComponent/>
        </div>
        <ContainerBox>
            <Divider light/>
            <WrapperBox>
                <div onClick={() => sm && onChangeOpenMobileLeft()}> 
                    {
                        sm ? (
                            <Button 
                                size='small' 
                                disableElevation
                                className={classes.expand}
                                startIcon={openMobileLeft ? <ExpandLessIcon fontSize="small" color="action"/> : <ExpandMoreIcon fontSize="small" color="action"/>}
                            >
                                {f({id: 'component.Survey.Workspace.Left.Head.folder.title'})}
                            </Button>
                        ) : (
                            <Typography variant='body2' component="div" style={{paddingLeft: 12, color: oc.gray[6], fontSize: 13}}>
                                {f({id: 'component.Survey.Workspace.Left.Head.folder.title'})}
                            </Typography>
                        )
                    }
                </div>
                <div>
                    <Tooltip title={f({id: 'component.Survey.Workspace.Left.Head.search'})}>
                        <IconButton size="small" onClick={() => changeOpenSearch(true)} style={{marginRight: 16}}>   
                            <SearchIcon fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={f({id: 'component.Survey.Workspace.Left.Head.addFolder'})}>
                        <IconButton size='small' onClick={() => changeOpenAdd(true)}>
                            <CreateNewFolderIcon/>
                        </IconButton>
                    </Tooltip>
                </div>
            </WrapperBox>
        </ContainerBox>
        <Divider style={{ background: oc.gray[0] }}/>
        {
            useMemo(() => {
                return (
                    <SurveyWorkspaceLeftCreatePopComponent open={openAdd} onClose={() => changeOpenAdd(false)}/>
                )
            }, [openAdd, changeOpenAdd])
        }
        {
            useMemo(() => {
                return (
                    <SurveyWorkspaceLeftSearchPopComponent open={openSearch} onClose={() => changeOpenSearch(false)}/>
                )
            }, [openSearch, changeOpenSearch])
        }
        </>
    )
}

export default withBreakPoints(Component)