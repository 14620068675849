import { memo } from 'react'
import { useIntl } from 'react-intl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { ConditionContextProps, AnswerType, FileType } from './Items';
import { OnChangeRowsAnswerType, OnChangeRowsFileType } from './Condition';

interface Props {
    rows: ConditionContextProps['rows'];
    onChangeAnswerType: OnChangeRowsAnswerType;
    onChangeFileType: OnChangeRowsFileType;
}

const CheckBox = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: 20
    }
})(Box)

function SearchComponent(props: Props) {
    const { rows, onChangeAnswerType, onChangeFileType } = props
    const { answerType, fileType } = rows

    const { formatMessage: f } = useIntl()
    
    return (
        <Grid container justifyContent="space-between" alignItems="center" style={{marginTop: 10}}>
            <Grid item>
                <Typography>{f({id: 'component.Survey.Workspace.Left.Utility.Download.ConditionRows.title'})}</Typography>
            </Grid>
            <Grid item>
                <CheckBox>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">{f({id: 'component.Survey.Workspace.Left.Utility.Download.ConditionRows.answerType.title'})}</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={f({id: 'component.Survey.Workspace.Left.Utility.Download.ConditionRows.answerType.title'})}
                        style={{width: 100}}
                        value={answerType}
                        onChange={onChangeAnswerType}
                        >
                        <MenuItem value={AnswerType.String}>{f({id: 'component.Survey.Workspace.Left.Utility.Download.ConditionRows.answerType.item.0'})}</MenuItem>
                        <MenuItem value={AnswerType.Number}>{f({id: 'component.Survey.Workspace.Left.Utility.Download.ConditionRows.answerType.item.1'})}</MenuItem>
                        <MenuItem value={AnswerType.All}>{f({id: 'component.Survey.Workspace.Left.Utility.Download.ConditionRows.answerType.item.2'})}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">{f({id: 'component.Survey.Workspace.Left.Utility.Download.ConditionRows.fileType.title'})}</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={f({id: 'component.Survey.Workspace.Left.Utility.Download.ConditionRows.fileType.title'})}
                        style={{width: 100}}
                        value={fileType}
                        onChange={onChangeFileType}
                        >
                        <MenuItem value={FileType.Csv}>CSV</MenuItem>
                        <MenuItem value={FileType.Xlsx}>XLSX</MenuItem>
            
                        </Select>
                    </FormControl>
                </CheckBox>
            </Grid>
        </Grid>
    )
}

export default memo(SearchComponent)