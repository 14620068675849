import { memo, useState, MouseEvent, useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { UPDATE_SURVEY_WORKSPACE_GROUP_SORTING } from 'gql/survey_workspace_group'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from 'styled/Custom/Tooltip'
import { ItemsProp } from './Component'
import { Items } from './Data'
import ItemComponent from './Item'
import { GroupContextProps } from 'container/Survey/Workspace/Group'

interface Props {
    items: Items;
    selected: ItemsProp['survey_workspace_group_no'];
    title: ItemsProp['title'];
    refetch: () => void;
    onChange: GroupContextProps['onChange'];
}

type OnDrag = (a: any) => void;

export type OnChange = GroupContextProps['onChange'];

const MyListItem = withStyles({
    root: {
        textOverflow: 'ellipsis',
        width: '100%',
        paddingLeft: 12,
    },
    button: {
        borderRadius: 5
    }
})(ListItem)


export const getDefaultWorkspaceGroupName = (f: any, title: string) => {
    if (!title) return f({id: 'component.Survey.Workspace.Body.MoveWorkspaceButtonSelect.default'})

    return title
}

export const DropID = 'workspaceGroupDrop'

let timer: ReturnType<typeof setTimeout> 

function ItemsComponent(props: Props) {
    const { items, selected, title, refetch, onChange } = props

    const { formatMessage: f } = useIntl()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const [ newItems, setNewItems ] = useState(items)

    const [ updateSorting ] = useMutation(UPDATE_SURVEY_WORKSPACE_GROUP_SORTING, {
        onCompleted: () => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                refetch()
            }, 100)
        }
    })

    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    }
  
    const handleClose = () => {
      setAnchorEl(null)
    }

    const handleChange: OnChange = useCallback((survey_workspace_group_no) => {
        onChange(survey_workspace_group_no)
        setAnchorEl(null)
    }, [onChange])


    // DnD 정렬 함수
    const handleDrag: OnDrag = (result) => {
        if (newItems.length <= 1) return
        
        // survey_question 복사
        const copy = newItems.map(c => c)

        const sourceIndex = result.source.index //원래 인덱스
        const destinationIndex = result.destination.index

        // 옮겨져야할 값 을 배열에서 삭제하고 value값은 저장
        const [removedData] = copy.splice(sourceIndex, 1)

        // 옮겨져야할 값 추가
        copy.splice(destinationIndex, 0, removedData)

        setNewItems(copy)

        updateSorting({
            variables: {
                survey_workspace_group_nos: copy.map(c => c.survey_workspace_group_no)
            }
        })
    }

    useEffect(() => {
        setNewItems(items)
    }, [items])

    if (selected === 0) return null

    const newTitle = getDefaultWorkspaceGroupName(f, title)

    return (
        <> 
        <Tooltip title={newTitle}>
            <List dense>
                <MyListItem dense button disableGutters onClick={handleClick}>
                    <ListItemText>
                        <Typography noWrap variant='body2'>{newTitle}</Typography>
                    </ListItemText>
                    <ListItemSecondaryAction onClick={handleClick}>
                        <IconButton size='small'>
                            <ArrowDropDownIcon/>
                        </IconButton>                  
                    </ListItemSecondaryAction>
                </MyListItem>
            </List>
           
        </Tooltip>
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <span>
                <DragDropContext onDragEnd={handleDrag} >
                    <Droppable droppableId={DropID}>
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef}  {...provided.droppableProps}> 
                        {
                            newItems.map((c, i) => {
                                const { survey_workspace_group_no, title } = c
                                return (
                                    <ItemComponent 
                                        key={survey_workspace_group_no} 
                                        i={i}
                                        survey_workspace_group_no={survey_workspace_group_no} 
                                        title={title}
                                        selected={selected === survey_workspace_group_no}
                                        onChange={handleChange}
                                    />
                                )
                            })
                        }
                        {provided.placeholder}    
                        </div>
                    )}
                    </Droppable>
                </DragDropContext>
            </span>
        </Menu>
        </>
    )
}






export default memo(ItemsComponent)