import { useState } from 'react'
import { useIntl } from 'react-intl'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Typography from '@material-ui/core/Typography'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import DownloadComponent from './Download/Component'
import AnalysisComponent from './Analysis/Component'

interface Props {
    survey_workspace_no: number;
}

function ResultComponent(props: Props) {
    const { survey_workspace_no } = props

    const { formatMessage: f } = useIntl()

    const [ open, setOpen ] = useState(false)
    
    function handleToggle() {
        setOpen(prevState => !prevState)
    }   

    return (
        <>
        <Divider/>
        <List dense>
            <ListItem style={{ cursor: 'pointer' }} onClick={handleToggle}>
                <ListItemText primary={
                    <Typography variant="body2">
                        {f({id: 'component.Survey.Workspace.Left.Utility.Result.Component.title'})}
                    </Typography>
                }/>
                <ListItemSecondaryAction onClick={handleToggle}>
                    {!open ? <ArrowDropDownIcon color="action"/> : <ArrowDropUpIcon color="action"/> }
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={open}>
                <List 
                    component="div" 
                    dense 
                    disablePadding 
                >
                    <AnalysisComponent survey_workspace_no={survey_workspace_no}/>
                    <DownloadComponent/>
                </List>
            </Collapse>
        </List>
        </>
    )
}

export default ResultComponent