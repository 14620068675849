import { memo, createContext, useState, useEffect, useCallback, ChangeEvent } from 'react'
import { useLazyQuery } from '@apollo/client'
import { SurveyWorkspaceProps } from 'gql/survey_workspace'
import { GET_SURVEY_MBOX_DESIGNS_IN_WORKSPACE_ACTION_DOWNLOAD, SurveyMboxDesignsInWorkspaceActionDownloadProps } from 'gql/survey_mbox'
import { Step } from 'gql/survey_analysis_summary'
import { parseGraphqlDate } from 'ts-utils/summary'
import { vaildYearMember } from 'ts-utils/user'
import oc from 'open-color'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import { getDate } from 'styled/Survey/Analysis/Calendar'
import useTokenInfo from 'hooks/useTokenInfo'
import ItemComponent from './Item'
import SearchComponent from './Search'
import ConditionComponent from './Condition'
import SearchGridComponent from './SearchGrid'
import { getSurveyWorkspaceNo } from "container/Survey/Workspace/Left"

export type OnChangeWorkspace = (e: ChangeEvent<HTMLInputElement>) => void
export type OnChangeSurveyWdate = (e: string) => void
export type OnInitSurveyWdate = () => void
export type OnChangeSurveyTitle = (e: string) => void

export enum AnswerType {
    String,
    Number,
    All
}

export enum FileType {
    Csv='csv',
    Xlsx='xlsx'
}

// 연결제자
export type YearMember = boolean

// 검색창 펼침 닫힘
export type Collapse = boolean
export type OnOpenCollapse = () => void
export type OnCloseCollapse = () => void

interface Props { 
    workspaceRows: SurveyWorkspaceProps[];
    selected: number;
}

// 조건절
interface ConditionProps {
    step: Step;
    date: string;
    rows: {
        answerType: AnswerType,
        fileType: FileType
    },
    personal: {
        isAnswerd: boolean
    }
}

const initCondition = {
    step: Step.End,
    date: '',
    rows: {
        answerType: AnswerType.String,
        fileType: FileType.Csv
    },
    personal: {
        isAnswerd: false
    }
}

type OnChangeCondition = (val: ConditionProps) => void

export interface ConditionContextProps extends ConditionProps {
    onChange: OnChangeCondition;
}

export const ConditionContext = createContext<ConditionContextProps>({...initCondition, onChange: (val) => {}})

const GridItem1 = withStyles(theme => ({
    root: {
        background: oc.gray[0],
        minHeight: 'calc(100vh - 64px)', 
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(100vh - 124px)'
        }
    }
}))(Grid)

const SerchBox = withStyles(theme => ({
    root: {
        width: '100%',
        padding: 20
    }
}))(Box)

const ItemContainer = withStyles(theme => ({
    root: {
        paddingTop: 40, 
        paddingBottom: 40,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 20, 
            paddingBottom: 20
        }
    }
}))(Container)

function ItemsComponent(props: Props) {
    const { workspaceRows, selected } = props

    const token = useTokenInfo()

    // 연 회원인지 판단
    const yearMember = vaildYearMember(token.year_date)

    // 설문지 검색
    const [ workspaceNo, setWorkspaceNo ] = useState(getSurveyWorkspaceNo(workspaceRows, selected))
    const [ surveyWdate, setSurveyWdate ] = useState("")
    const [ surveyTitle, setSurveyTitle ] = useState("")

    // 다운로드 검색 조건
    const [ condition, setCondition ] = useState(initCondition)

    // 검색창 펼팀 닫힘
    const [ collapse, setCollapse ] = useState(true)

    const [ getMbox, { data } ] = useLazyQuery<{ surveyMboxDesignsInWorkspaceActionDownload: SurveyMboxDesignsInWorkspaceActionDownloadProps[]}>(GET_SURVEY_MBOX_DESIGNS_IN_WORKSPACE_ACTION_DOWNLOAD, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
         
        }
    })

    // 검색 작업함 변경
    const handleChangeWorkspace: OnChangeWorkspace = useCallback(e => {
        const { value } = e.target
        setWorkspaceNo(Number(value))
    }, [])

    // 설문지 생성일자 검색
    const handleChangeSurveyWdate: OnChangeSurveyWdate = useCallback(val => {
        setSurveyWdate(val)
    }, [])

    // 설문지 생성일자 검색
    const handleInitSurveyWdate: OnInitSurveyWdate = useCallback(() => {
        setSurveyWdate('')
    }, [])

    // 검색 설문 타이틀 검색
    const handleChangeSurveyTitle: OnChangeSurveyTitle = useCallback(val => {
        setSurveyTitle(val)
    }, [])


    // 다운로드 조건 설정
    const handleChangeCondition: OnChangeCondition = useCallback((val) => {
        setCondition(val)
    }, [])

    // 검색창 펼침 닫힘
    const handleOpenCollapse = useCallback(() => {
        setCollapse(true)
    }, [])

    const handleCloseCollapse = useCallback(() => {
        setCollapse(false)
    }, [])

    useEffect(() => {
        const { sdate, edate } = getDate(surveyWdate)
        const parseDate = parseGraphqlDate(sdate, edate)

        // 한글자 일때는 검색 액션 안취한다.
        if (surveyTitle.length === 1) return

        getMbox({
            variables: {
                survey_workspace_no: workspaceNo,
                sdate: parseDate.sdate,
                edate: parseDate.edate,
                title: surveyTitle
            }
        })
    }, [workspaceNo, surveyWdate, surveyTitle, getMbox])

    return (
        <Grid container spacing={0}>
            <SearchGridComponent collapse={collapse} onOpen={handleOpenCollapse} onClose={handleCloseCollapse}>
                <SerchBox>
                    <SearchComponent 
                        workspaceRows={workspaceRows} 
                        workspaceNo={workspaceNo} 
                        date={surveyWdate}
                        title={surveyTitle}
                     
                        onChangeWorkspace={handleChangeWorkspace}
                        onChangeSurveyWdate={handleChangeSurveyWdate}
                        onInitSurveyWdate={handleInitSurveyWdate}
                        onChangeSurveyTitle={handleChangeSurveyTitle}
                    />
                </SerchBox>
                <Divider/>
                <SerchBox>
                    <ConditionComponent {...condition} onChange={handleChangeCondition} onCloseCollapse={handleCloseCollapse}/>
                </SerchBox>
                <Divider/>
                    

            </SearchGridComponent>
            <GridItem1 item xs={12} sm={12} md={8}>
                <ItemContainer>
                    <ConditionContext.Provider value={{...condition, onChange: handleChangeCondition}}>
                        <Grid container spacing={2}>
                            {
                                data ? data.surveyMboxDesignsInWorkspaceActionDownload.map(row => {
                                    const { survey_no, title, wdate, survey_analysis_user } = row
                                    const { is_response } = survey_analysis_user
                                    return (
                                        <ItemComponent 
                                            key={survey_no} 
                                            survey_no={survey_no} 
                                            title={title} 
                                            wdate={wdate} 
                                            is_response={is_response}
                                            yearMember={yearMember}
                                        />
                                    )
                                }) : (
                                    <CircularProgress color="primary" style={{marginTop: 10, marginLeft: 10}}/>
                                )
                            }
                        </Grid>
                    </ConditionContext.Provider>
                </ItemContainer>
            </GridItem1>
        </Grid>
    )
}

export default memo(ItemsComponent)