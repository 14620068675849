import { memo } from 'react'
import { SurveyMboxDesignsInWorkspaceActionDownloadProps } from 'gql/survey_mbox'
import oc from 'open-color'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'
import DownloadButtonComponent from './DownloadButton'
import { YearMember } from './Items'

interface Props {
    survey_no: SurveyMboxDesignsInWorkspaceActionDownloadProps['survey_no'];
    title: SurveyMboxDesignsInWorkspaceActionDownloadProps['title'];
    wdate: SurveyMboxDesignsInWorkspaceActionDownloadProps['wdate'];
    is_response: SurveyMboxDesignsInWorkspaceActionDownloadProps['survey_analysis_user']['is_response'];
    yearMember: YearMember;
    breakpoints: BreakPointsProps;
}

const MyPaper = withStyles({
    root: {
        width: '100%'
    }
})(Paper)

const MyBox = withStyles({
    root: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        height: 80,
        overflowY: 'auto',
        wordBreak: 'break-all',
        padding: 10
    }
})(Box)

 const GridDateBox = withStyles(theme => ({
    root: {
        display: 'flex',
        paddingLeft: 10,
        paddingTop: 5,
        [theme.breakpoints.down('xs')]: {
            justfiyContent: 'center',
            paddingLeft: 0,
            paddinTop: 0
        } 
    }
}))(Box)

const GridButtonBox = withStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: 2,
        paddingTop: 5,
        [theme.breakpoints.down('xs')]: {
            justfiyContent: 'center',
            paddingRight: 0,
            paddinTop: 0
        } 
    }
}))(Box)

function ItemComponent(props: Props) {
    const { survey_no, title, wdate, is_response, yearMember, breakpoints } = props
    const { xs } = breakpoints

    return (
        <Grid item xs={6} sm={4} md={3}>
            <MyPaper>
                <MyBox>
                    <Typography variant="body2" style={{color: oc.gray[7]}} component="div">{title}</Typography>
                </MyBox>
                <Divider/>
                <Grid 
                    container 
                    spacing={1} 
                    alignItems="center" 
                    justifyContent={xs ? 'center' : 'space-between'} 
                    direction={xs ? 'column-reverse' : 'row'}
                    style={xs ? {paddingTop: 10, paddingBottom: 10} : {}}
                >
                    <Grid item xs={12} sm={4} md={4}>
                        <GridDateBox>
                            <Typography variant="body2" component="div">{moment(Number(wdate)).format('YYYY.MM.DD')}</Typography>
                        </GridDateBox>
                    </Grid>
                   
                    <Grid item xs={12} sm={8} md={8}>
                        <GridButtonBox>
                            <DownloadButtonComponent survey_no={survey_no} title={title} is_response={is_response} yearMember={yearMember}/>
                        </GridButtonBox>
                    </Grid>
                    
                </Grid>
            </MyPaper>
        </Grid>   
    )
}

export default memo(withBreakPoints(ItemComponent))