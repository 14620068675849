import { useIntl } from 'react-intl'
import IconButton from '@material-ui/core/IconButton'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Dialog from 'styled/Survey/Live/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import { OnTemplateClose } from '../SelectCreateBox'
import ContentComponent from './Content'

interface Props {
    open: boolean
    onClose: OnTemplateClose
}

const MyIconButton = withStyles(theme => ({
    root: {
        position: 'absolute', 
        right: 0, 
        top: 0,
        [theme.breakpoints.down('xs')]: {
            visibility: 'hidden'
        }
    }
}))(IconButton)

const MyDialogActions = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    }
}))(DialogActions)

function TemplateComponent(props: Props) {
    const { open, onClose } = props

    const { formatMessage: f } = useIntl()

    return (
        <Dialog open={open} onClose={onClose}>
            <Container maxWidth="lg" style={{position: 'relative'}}>     
                <MyIconButton onClick={onClose}>
                    <CloseIcon/>
                </MyIconButton>
            </Container>
            <DialogContent>
                {open && <ContentComponent/>}
            </DialogContent>
            <MyDialogActions>
                <Button onClick={onClose}>{f({id: 'component.Survey.Workspace.Body.Template.Component.close'})}</Button>
            </MyDialogActions>
        </Dialog>
    )
}

export default TemplateComponent