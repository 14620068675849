import { useEffect, useState, useCallback, useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/react-hooks'
import oc from 'open-color'
import PropsContext from 'context/props'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import styled from 'styled-components'
import { List, ListItem, ListItemText, Typography, ListSubheader, Input, InputAdornment } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { StyledDialog } from 'styled/Modal'
import { useIntl } from 'react-intl'
import { GET_SURVEY_WORKSPACES_AND_MBOXS } from 'gql/survey_mbox'
import { getMaxlength } from 'policy'
import { GroupContext } from 'container/Survey/Workspace/Group'

const useStyles = makeStyles(theme => ({
    content: {
        margin: '0 auto',
        marginTop: 50,
        width: '100%',
        maxWidth: 900,
        paddingRight: '1em',
        paddingLeft: '1em'
    },
    lists: {
        width: '100%',
        backgroundColor: 'transparent',
        overflowY: 'auto'
    },
    subheader: {
        background: oc.gray[0]
    }
}))

const WrapperStyled = styled.div`
        margin: 0 auto;
        margin-top: 50px;
        width: 100%;
        max-width: 900px;
        padding-right: 1em;
        padding-left: 1em;
`

const ListsBoxStyled = styled.div`
    display: flex;
    flex-direction: row;
    background: ${oc.gray[0]};
    max-height: calc(100vh - 10em);
`

const initialState = {
    title: '',
    data: { workspaces: [], mboxs: [] }
}

let time = null

const Component = (props) => {
    const { open, onClose } = props
    
    const { workspaceRows, onChangeSelected } = useContext(PropsContext)
    const { survey_workspace_group_no } = useContext(GroupContext)

    const survey_workspace_nos = workspaceRows.map(c => c.survey_workspace_no)

    const history = useHistory()

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    const [ title, setTitle ] = useState(initialState.title)

    const [ data, setData ] = useState(initialState.data)

    const [getSurveyWorkspacesAndMboxs] = useLazyQuery(GET_SURVEY_WORKSPACES_AND_MBOXS, {
        onCompleted: (res) => {
            const { surveyWorkspacesAndMboxs } = res
            
            setData(surveyWorkspacesAndMboxs)
        },
        onError: (e) => {
            dispatch({ type: ERRORPAGESHOW })
        },
        fetchPolicy: 'network-only'
    })

    const handleChange = useCallback(async(e) => {
        const { value } = e.target
        setTitle(value)

        clearTimeout(time)
        time = setTimeout(() => {
            getSurveyWorkspacesAndMboxs({
                variables: {
                    survey_workspace_group_no,
                    survey_workspace_nos,
                    title: value
                }
            })
        }, 500)

    }, [survey_workspace_nos, survey_workspace_group_no, getSurveyWorkspacesAndMboxs])


    const handleChangeWorkspace = useCallback(async(survey_workspace_no) => {
        await onChangeSelected(survey_workspace_no)
        onClose()
    }, [onChangeSelected, onClose])

    const handleMoveSurvey = useCallback((survey_no) => {
        history.push(`/survey/form/create/${survey_no}`)
    }, [history])
    

    // 창닫히면 title 값 빈값으로 변경
    useEffect(() => {
        if (!open) {
            setTitle(initialState.title)
            setData(initialState.data)
        }
    }, [open])

    return (
        <>
        <StyledDialog open={open} onClose={onClose} scroll={'paper'} fullScreen onClick={onClose}>
            <WrapperStyled onClick={(e) => e.stopPropagation()}>
                {
                    useMemo(() => {
                        return (
                            <>
                            <Input
                                placeholder={f({id: 'component.Survey.Workspace.Left.SearchPop.input.placeholder'})}
                                inputProps={{
                                    maxLength: getMaxlength('component.Survey.Create.Step.title')
                                }}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon color="action"/>
                                    </InputAdornment>
                                }
                                value={title}
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                autoFocus      
                            />
                            <Typography variant="caption" color="textSecondary">
                                {f({id: 'component.Survey.Workspace.Left.SearchPop.tip'})}
                            </Typography>
                            </>
                        )
                    }, [f, title, handleChange])
                }
                
                <ListsBoxStyled>
                    {
                        useMemo(() => {
                            return (
                                data.workspaces.length > 0 && (
                                    <List 
                                        component="nav" 
                                        className={`${classes.lists} scroll`}
                                        subheader={
                                            <ListSubheader component="div" className={classes.subheader}>
                                                {f({id: 'component.Survey.Workspace.Left.SearchPop.workspace.title'})}
                                            </ListSubheader>
                                        }
                                    >
                                        {
                                            data.workspaces.map(c => (
                                                <ListItem button key={c.survey_workspace_no} onClick={() => handleChangeWorkspace(c.survey_workspace_no)}>
                                                    <ListItemText>
                                                        <Typography noWrap>{c.title}</Typography>
                                                    </ListItemText>
                                                </ListItem>        
                                            ))
                                        }   
                                        
                                    </List>
                                )
                            )
                        }, [f, data.workspaces, classes, handleChangeWorkspace])
                    }
                    {
                        useMemo(() => {
                            return (
                                data.mboxs.length > 0 && (
                                    <List 
                                        component="nav" 
                                        className={`${classes.lists} scroll`}
                                        subheader={
                                            <ListSubheader component="div" className={classes.subheader}>
                                                {f({id: 'component.Survey.Workspace.Left.SearchPop.mbox.title'})}
                                            </ListSubheader>
                                        }
                                    >
                                        {
                                            data.mboxs.map(c => {
                                                const row = workspaceRows.find(d => c.survey_workspace_no === d.survey_workspace_no)
                                                
                                                let workspaceTitle = f({id: 'component.Survey.Workspace.Left.Lists.defaultTitle'})
                                                
                                                if (row) {
                                                    if (!row.base) workspaceTitle = row.title
                                                }

                                                return (
                                                    <ListItem button key={c.survey_no} onClick={() => handleMoveSurvey(c.survey_no)}>
                                                        <ListItemText 
                                                            primary={<Typography noWrap>{c.title}</Typography>} 
                                                            secondary={<Typography noWrap variant="body2" color="textSecondary">{workspaceTitle}</Typography>}
                                                        />        
                                                    </ListItem>        
                                                )
                                                
                                            })
                                        }   
                                        
                                    </List>
                                )
                            )
                        }, [f, data.mboxs, workspaceRows, classes, handleMoveSurvey])
                    }    
                </ListsBoxStyled>
            </WrapperStyled>
            
        </StyledDialog>
        </>
    )
}

export default Component