import { memo, useState, useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { UPDATE_SURVEY_WORKSPACE_GROUP_NAME } from 'gql/survey_workspace_group'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage';
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import EditIcon from '@material-ui/icons/Edit'
import TitleDialogComponent, { Type } from './TitleDialog'
import { OnClose } from './More'
import { ItemsProp } from './Component'

interface Props {
    survey_workspace_group_no: ItemsProp['survey_workspace_group_no'];
    title: ItemsProp['title'];
    refetch: () => void;
    onClose: OnClose;
}

export type OnSave = (a: string) => void;

function UpdateNameComponent(props: Props) {
    const { survey_workspace_group_no, title, refetch, onClose } = props
    
    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const [ open, setOpen ] = useState(false)

    const [ update ] = useMutation(UPDATE_SURVEY_WORKSPACE_GROUP_NAME, {
        onCompleted: () => {
            setTimeout(() => {
                refetch()
                setOpen(false)
            }, 100)
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const handleOpen = () => {
        onClose()
        setOpen(true)
    }

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const handleSave: OnSave = useCallback((title) => {
        update({
            variables: {
                survey_workspace_group_no,
                title
            }
        })

    }, [update, survey_workspace_group_no])

    return (
        <>
        <MenuItem dense onClick={handleOpen}>
            <ListItemIcon>
                <EditIcon fontSize="small"/>
            </ListItemIcon>
            <Typography variant="inherit">
                {f({id: 'component.Survey.Workspace.Left.Group.UpdateName.title'})}
            </Typography>
        </MenuItem>
        <TitleDialogComponent type={Type.Update} open={open} title={title} onClose={handleClose} onSave={handleSave}/> 
        </>
    )
}

export default memo(UpdateNameComponent)