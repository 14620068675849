import { memo, useCallback, useState, useEffect } from 'react'
import DateButton from 'styled/Survey/Analysis/DateButton'
import { Props as ParentProps } from './Search'

interface Props {
    date: ParentProps['date'];
    onChange: ParentProps['onChangeSurveyWdate'];
    onInit: ParentProps['onInitSurveyWdate'];
}

function SearchDateComponent(props: Props) {
    const { date, onChange, onInit } = props
    
    const [ dateOpen, setDateOpen ] = useState(false)

    const handleChangeDateOpen = useCallback(() => {
        setDateOpen(true)
    }, [])

    const handleChangeDateClose = useCallback(() => {
        setDateOpen(false)
    }, [])

    useEffect(() => {
        handleChangeDateClose()
    }, [date, handleChangeDateClose])

    return (
        <DateButton 
            style={{backgroundColor: 'white', height: 56, width: '100%'}}
            cookieName=""
            open={dateOpen} 
            date={date} 
            onInitial={onInit} 
            onOpen={handleChangeDateOpen} 
            onClose={handleChangeDateClose} 
            onDo={onChange}
        />
    )
}

export default memo(SearchDateComponent)